import { withFormik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import withFormWrap from '../../FormFields/helpers/withFormWrap';
import DesignUserPasswordForm from './DesignUserPasswordForm';
import { initialValues, schema, withUpdate } from './helpers';

function DesignUserModalInner(props) {
  const { modalOpen, setModalOpen, handleSubmit } = props;

  return (
    <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head pr-4 is-flex is-flex-direction-row is-justify-content-space-between">
          <h4 className="title is-4 mb-4 modal-card-title">
            Please enter a new password
          </h4>
          <button
            type="button"
            className="delete is-align-self-flex-start"
            aria-label="close"
            onClick={() => setModalOpen(false)}
          />
        </header>
        <section className="modal-card-body">
          {modalOpen && <DesignUserPasswordForm />}
        </section>
        <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
          <button
            type="submit"
            className="button is-success is-outlined"
            onClick={() => {
              handleSubmit();
              setModalOpen(false);
            }}
          >
            <span className="icon">
              <i className="bx bx-check" />
            </span>
            <span>Set Password</span>
          </button>
        </footer>
      </div>
    </div>
  );
}

DesignUserModalInner.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default flowRight(
  withFormWrap({ initialValues, schema }),
  withUpdate,
  withFormik({
    mapPropsToValues: (props) => props.initialValues(props),
    validationSchema: (props) => props.schema(),
    enableReinitialize: true,
    handleSubmit: (values, { props, setSubmitting }) => {
      const variables = {
        variables: { input: { input: values, clientMutationId: uuidv4() } },
      };

      props.update(variables);

      setSubmitting(false);
    },
  }),
)(DesignUserModalInner);
