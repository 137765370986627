import { lazy } from 'react';

const LegalShowNavMenu = lazy(
  () => import('../../shared/NavMenus/Legal/LegalShowNavMenu'),
);

const routes = [
  {
    path: ['/:root(legal)', '/:root(legal)/:type(privacy-policy|baa)'],
    component: LegalShowNavMenu,
  },
];

export default routes;
