import PropTypes from 'prop-types';
import BasicDropdownItem from '../../../../shared/FormFields/Dropdowns/BasicDropdownItem';
import BillingInvoiceMemoFormModal from './BillingInvoiceMemoFormModal';

function BillingInvoiceMemoForm({
  modalOpen,
  openModal,
  closeModal,
  selectedItem = null,
  ...rest
}) {
  const props = { modalOpen, openModal, closeModal, selectedItem, ...rest };
  return (
    <>
      <BasicDropdownItem itemText="Edit Memo" clickHandler={openModal} />
      {selectedItem && (
        <BillingInvoiceMemoFormModal
          modalOpen={modalOpen}
          closeModal={closeModal}
          {...props}
        />
      )}
    </>
  );
}

BillingInvoiceMemoForm.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
};

export default BillingInvoiceMemoForm;
