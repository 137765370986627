import PropTypes from 'prop-types';

function formatDate(inputDate) {
  const date = new Date(inputDate);
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(date);
}

function GridCellDueTime({ value }) {
  const dueTime = Date.parse(value);
  return (
    <div style={{ color: Date.now() > dueTime ? '#E40000' : 'inherit' }}>
      {formatDate(value)}
    </div>
  );
}

GridCellDueTime.propTypes = {
  value: PropTypes.string.isRequired,
};

export default GridCellDueTime;
