import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { BoxIcon } from '../../../../shared';
import BillingChargeFormModal from './BillingChargeFormModal';

function BillingChargeForm({
  modalOpen,
  openModal,
  closeModal,
  action = null,
  billingType = 'Charge',
  ...rest
}) {
  const props = {
    modalOpen,
    openModal,
    closeModal,
    action,
    billingType,
    ...rest,
  };
  const { root } = useParams();

  return (
    <>
      {root !== 'billing' && (
        <button
          type="button"
          className="button mr-3"
          onClick={() => openModal('New')}
        >
          <BoxIcon name="bx-plus-medical" />
          <span>New {billingType}</span>
        </button>
      )}

      <BillingChargeFormModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        {...props}
        action={action}
      />
    </>
  );
}

BillingChargeForm.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  action: PropTypes.string,
  billingType: PropTypes.string,
};

export default BillingChargeForm;
