import { graphql } from '@apollo/client/react/hoc';
import { ACTION_DESIGN_USER_SET_PASS } from '@fullcontour/shared-api';
import { CreateNotification } from '../../..';

const withUpdate = graphql(ACTION_DESIGN_USER_SET_PASS, {
  name: 'update',
  options: (props) => ({
    onCompleted: (data) => {
      const { actionDesignUserSetPassword } = data;
      if (actionDesignUserSetPassword.errorMessages.length) {
        props.setErrorMessages(actionDesignUserSetPassword.errorMessages);
      } else {
        props.clearErrorMessages();
        props.setModalOpen(false);
        CreateNotification.createNotification({
          type: 'success',
          message: `Password has been successfully updated`,
        });
      }
    },
  }),
});

export default withUpdate;
