import PropTypes from 'prop-types';
import HeaderInfoPercentComplete from '../HeaderInfoPercentComplete';
import HeaderInfoProgressBar from '../HeaderInfoProgressBar';

function HeaderOrderState({ changeableLayout, isLoading, order = null }) {
  return !isLoading ? (
    <div className="is-flex is-flex-direction-column is-align-items-flex-end">
      {changeableLayout && (
        <h6 className="title is-6 header-">You are in editing mode</h6>
      )}
      {!changeableLayout && (
        <>
          <HeaderInfoPercentComplete
            state={order.state}
            isRush={order.isRush}
          />
          <HeaderInfoProgressBar
            state={order.state}
            isRush={order.isRush}
            percentComplete={order.percentComplete}
          />
        </>
      )}
    </div>
  ) : null;
}

HeaderOrderState.propTypes = {
  order: PropTypes.object,
  changeableLayout: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default HeaderOrderState;
