import PropTypes from 'prop-types';
import { AgGridContainer } from '../../../../../shared';

function ResultsAssignmentDesignSilo(props) {
  const { reportDetails } = props;

  const gridOptions = {
    rowData: reportDetails,
    defaultColDef: {
      floatingFilter: true,
    },
    suppressAggFuncInHeader: true,
    columnDefs: [
      {
        field: 'name',
        headerName: 'Design Silo',
        minWidth: 150,
        sort: 'asc',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'totalOrders',
        headerName: 'Orders Assigned',
        minWidth: 130,
        type: 'numericColumn',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'totalUnits',
        headerName: 'Units Assigned',
        type: 'numericColumn',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'reassigned',
        headerName: 'Orders Reassigned',
        type: 'numericColumn',
        minWidth: 145,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'reassignedUnits',
        headerName: 'Units Reassigned',
        type: 'numericColumn',
        minWidth: 135,
        filter: 'agTextColumnFilter',
      },
      {
        field: '12am',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '1am',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '2am',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '3am',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '4am',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '5am',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '6am',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '7am',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '9am',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '10am',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '11am',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '12pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '1pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '2pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '3pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '4pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '5pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '6pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '7pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '9pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '10pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
      {
        field: '11pm',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
    ],
  };

  return <AgGridContainer gridOptions={gridOptions} />;
}

ResultsAssignmentDesignSilo.propTypes = {
  reportDetails: PropTypes.array.isRequired,
};

export default ResultsAssignmentDesignSilo;
