import PropTypes from 'prop-types';
import { GridContainer } from '../../../shared';

function ReportsListDataTable(props) {
  const { data } = props;

  const gridOptions = {
    rowData: data.reports,
    defaultColDef: {
      floatingFilter: true,
    },
    columnDefs: [
      {
        field: 'name',
        minWidth: 200,
        sort: 'asc',
        filter: 'agTextColumnFilter',
        cellStyle: { color: '#1B77C8', cursor: 'pointer' },
        onCellClicked: (p) => {
          p.context.history.push(`/reports/saved/${p.data.id}`);
        },
      },
      {
        field: 'reportType.name',
        headerName: 'Report Type',
        minWidth: 200,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'reportTimeFrame.name',
        headerName: 'Time Period',
        minWidth: 200,
        filter: 'agTextColumnFilter',
      },
    ],
  };

  return (
    <GridContainer
      rootVal="reports"
      gridOptions={gridOptions}
      to="/reports/new"
      content="New Report"
    />
  );
}

ReportsListDataTable.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ReportsListDataTable;
