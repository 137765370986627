import * as Yup from 'yup';

function schema(props) {
  return Yup.object({
    invoiceId: Yup.string().required('An Invoice is required'),
    creditId: Yup.string().required('A Credit is required'),
    amountUsed: Yup.number()
      .positive('Must be a valid Amount')
      .required('Required')
      .max(props.maxAmount)
      .typeError('Must be a valid Amount'),
  });
}

export default schema;
