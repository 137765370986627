import PropTypes from 'prop-types';
import { AgGridContainer } from '../../../../../shared';

function ResultsInvoicesCustomer(props) {
  const { reportDetails } = props;

  const gridOptions = {
    rowData: reportDetails,
    defaultColDef: {
      floatingFilter: true,
    },
    rowSelection: 'single',
    columnDefs: [
      {
        headerName: 'Details',
        marryChildren: true,
        children: [
          {
            field: 'hex_invoice_number',
            headerName: 'Invoice ID',
            minWidth: 120,
            sort: 'desc',
            filter: 'agTextColumnFilter',
            cellStyle: {
              textTransform: 'uppercase',
              color: '#1B77C8',
              cursor: 'pointer',
            },
            onCellClicked: (params) => {
              const link = `/organizations/customer/${params.data.lab_organization_slug}/billing/invoices/${params.data.hex_invoice_number}`;

              params.context.history.push(link);
            },
          },
          {
            field: 'lab_organization_name',
            headerName: 'Organization',
            minWidth: 150,
            filter: 'agTextColumnFilter',
            cellStyle: { color: '#1B77C8', cursor: 'pointer' },
            onCellClicked: (params) => {
              params.context.history.push(
                `/organizations/customer/${params.data.lab_organization_slug}`,
              );
            },
          },
          {
            field: 'lab_location_name',
            headerName: 'Location',
            minWidth: 150,
            filter: 'agTextColumnFilter',
            cellStyle: { color: '#1B77C8', cursor: 'pointer' },
            onCellClicked: (params) => {
              if (params.data.location.slug) {
                params.context.history.push(
                  `/locations/customer/${params.data.lab_location_slug}`,
                );
              }

              return null;
            },
          },
          {
            field: 'state',
            headerName: 'Status',
            minWidth: 120,
            filter: 'agSetColumnFilter',
            cellRenderer: (params) => {
              const span = document.createElement('span');
              let labelClass;

              if (params.value === 'paid') {
                labelClass = 'tag is-success';
              }

              if (params.value === 'error') {
                labelClass = 'tag is-danger';
              }

              if (params.value === 'past_due') {
                labelClass = 'tag is-warning';
              }

              if (params.value === 'unpaid') {
                labelClass = 'tag is-danger';
              }

              if (params.value === 'voided') {
                labelClass = 'tag';
              }

              const label = document.createElement('div');

              label.className = labelClass;
              label.style =
                'margin: 2% 5px; vertical-align: middle; display: inline-block; text-transform: capitalize; min-width: 80px; text-align: center';
              label.innerHTML = params.value;

              span.append(label);

              return span;
            },
          },
          {
            field: 'memo',
            minWidth: 250,
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
        ],
      },
      {
        headerName: 'Dates',
        marryChildren: true,
        children: [
          {
            field: 'invoicing_date',
            headerName: 'Invoice Date',
            minWidth: 160,
            type: 'dateColumn',
          },
          {
            field: 'due_date',
            headerName: 'Due Date',
            minWidth: 160,
            type: 'dateColumn',
          },
          {
            field: 'date_paid',
            headerName: 'Date Paid',
            columnGroupShow: 'open',
            minWidth: 160,
            type: 'dateColumn',
          },
        ],
      },
      {
        headerName: 'Amounts',
        marryChildren: true,
        children: [
          {
            field: 'total_price',
            headerName: 'Invoice Amount',
            minWidth: 120,
            filter: 'agTextColumnFilter',
            valueFormatter: (p) => `$${Number.parseFloat(p.value).toFixed(2)}`,
          },
          {
            field: 'total_debits',
            headerName: 'Charges',
            columnGroupShow: 'open',
            minWidth: 120,
            filter: 'agTextColumnFilter',
            valueFormatter: (p) => `$${Number.parseFloat(p.value).toFixed(2)}`,
          },
          {
            field: 'total_credits',
            headerName: 'Credits',
            columnGroupShow: 'open',
            minWidth: 120,
            filter: 'agTextColumnFilter',
            valueFormatter: (p) =>
              `($${Number.parseFloat(p.value).toFixed(2)})`,
          },
          {
            field: 'applied_payments',
            headerName: 'Payments',
            minWidth: 120,
            filter: 'agTextColumnFilter',
            valueFormatter: (p) =>
              `($${Number.parseFloat(p.value).toFixed(2)})`,
          },
          {
            field: 'balance_due',
            headerName: 'Balance Due',
            minWidth: 120,
            filter: 'agTextColumnFilter',
            valueFormatter: (p) => `$${Number.parseFloat(p.value).toFixed(2)}`,
          },
        ],
      },
    ],
  };

  return <AgGridContainer gridOptions={gridOptions} />;
}

ResultsInvoicesCustomer.propTypes = {
  reportDetails: PropTypes.array.isRequired,
};

export default ResultsInvoicesCustomer;
