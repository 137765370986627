/* eslint-disable no-restricted-globals */
import { memo, useContext } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { AuthContext } from '@fullcontour/auth';
import PropTypes from 'prop-types';
import { LegalPage } from '../../views';
import { BreadCrumbContainer } from '../../layouts';
import LegalShowNavMenu from '../NavMenus/Legal/LegalShowNavMenu';

function LegalsHOC(props) {
  const { isSignedIn } = useContext(AuthContext);
  const history = useHistory();
  const { children } = props;
  return !isSignedIn && history.location.pathname.includes('/legal') ? (
    <>
      <div className="fresnel-container fresnel-greaterThanOrEqual-tablet breadCrumbs">
        <div
          style={{
            borderBottom: '1px solid rgb(221, 221, 221)',
            borderTop: '1px solid rgb(221, 221, 221)',
          }}
        >
          <BreadCrumbContainer />
        </div>
      </div>
      <div className="sidebarDesktopContainer">
        <div className="sidebarArea">
          <aside className="menu" style={{ width: '220px', position: 'fixed' }}>
            <ul className="menu-list">
              <LegalShowNavMenu />
            </ul>
          </aside>
        </div>
        <div className="sidebarMain" style={{ position: 'relative' }}>
          <Switch>
            <Route
              exact
              path={[
                '/:root(legal)',
                '/:root(legal)/:type(baa|privacy-policy)',
              ]}
              component={LegalPage}
            />
          </Switch>
        </div>
      </div>
    </>
  ) : (
    children
  );
}
LegalsHOC.propTypes = {
  children: PropTypes.node.isRequired,
};
export default memo(LegalsHOC);
