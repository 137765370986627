import { graphql } from '@apollo/client/react/hoc';
import { CREATE_CAD_FILE } from '@fullcontour/shared-api';

const withCreate = graphql(CREATE_CAD_FILE, {
  name: 'create',
  options: (props) => ({
    onCompleted: (data) => {
      const { createCadFile } = data;
      if (createCadFile.errorMessages.length) {
        props.setErrorMessages(createCadFile.errorMessages);
      } else {
        props.clearErrorMessages();
        const app = import.meta.env.VITE_APP_NAME;
        let url;

        if (app === 'admin_portal') {
          url = `/locations/customer/${createCadFile.cadFile.location.slug}`;
        }

        if (app === 'customer_portal' && props.currentUser.scopeToLocation) {
          url = '/locations';
        }

        if (app === 'customer_portal' && !props.currentUser.scopeToLocation) {
          url = `/locations/${createCadFile.cadFile.location.slug}`;
        }

        props.history.push(url);
      }
    },
  }),
});

export default withCreate;
