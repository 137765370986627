import { Policy } from '@fullcontour/common';

function Legal() {
  return (
    <div style={{ padding: '6em 2em' }}>
      <div className="container">
        <div className="columns">
          <div className="column is-6">
            <Policy policy="3Shape Design Service Terms and Conditions" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Legal;
