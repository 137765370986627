import { graphql } from '@apollo/client/react/hoc';
import { UPDATE_CONTACT } from '@fullcontour/shared-api';

const withUpdate = graphql(UPDATE_CONTACT, {
  name: 'update',
  options: (props) => ({
    onCompleted: (data) => {
      const { updateContact } = data;
      if (updateContact.errorMessages.length) {
        props.setErrorMessages(updateContact.errorMessages);
      } else {
        props.clearErrorMessages();
        props.refetch();
        props.closeModal();
      }
    },
  }),
});

export default withUpdate;
