/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ReviewModal } from '../../shared/ReviewModal';
import { checkPermissions } from '../helpers';

function ActionReviewCase(props) {
  const { t } = useTranslation('orderactions');
  const [modalOpen, setModalOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    const redesignsUrl = params.get('modal');
    if (redesignsUrl) {
      setModalOpen(true);
      history.replace({ state: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return checkPermissions(props) ? (
    <>
      <a className="dropdown-item" onClick={() => setModalOpen(true)}>
        {t('Review Case')}...
      </a>
      {modalOpen && (
        <ReviewModal
          closeModal={() => setModalOpen(false)}
          modalOpen={modalOpen}
          {...props}
        />
      )}
    </>
  ) : null;
}

export default ActionReviewCase;
