import { useMutation } from '@apollo/client';
import { ACTION_USER_RESET_PASS } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { handleTryCatch } from '../..';

function DesignUserModalInner(props) {
  const { modalOpen, setModalOpen, userId } = props;
  const {
    params: { type },
  } = useRouteMatch();

  const [resetPassword, { loading }] = useMutation(ACTION_USER_RESET_PASS);

  const submitHandler = handleTryCatch(async () => {
    const variables = {
      variables: {
        input: {
          input: {
            id: userId,
            klass:
              type === 'customer'
                ? 'User'
                : `${type[0].toUpperCase() + type.slice(1)}User`,
          },
          clientMutationId: uuidv4(),
        },
      },
    };
    await resetPassword(variables);
    setModalOpen(false);
  });

  return (
    <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <h4 className="title is-5 mb-5 modal-card-title">
            Are you sure you want to send a password reset e-mail to the user?
          </h4>
        </header>
        <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
          <button
            type="button"
            className="button is-danger"
            onClick={() => setModalOpen(false)}
          >
            <span className="icon is-small">
              <i className="bx bx-x" />
            </span>
            <span>No</span>
          </button>
          <button
            type="button"
            disabled={loading}
            className={`button is-primary ${loading && 'is-loading'}`}
            onClick={submitHandler}
          >
            <span className="icon is-small">
              <i className="bx bx-check" />
            </span>
            <span>Yes</span>
          </button>
        </footer>
      </div>
    </div>
  );
}

DesignUserModalInner.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  setModalOpen: PropTypes.func.isRequired,
};

export default DesignUserModalInner;
