import { FastField, Field, withFormik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  BoxIcon,
  FieldContainer,
  PhoneField,
  ServerErrors,
  withFormWrap,
} from '../../../../shared';
import { initialValues, schema, withCreate, withUpdate } from './helpers';

function BillingContactFormModal({
  modalOpen,
  closeModal,
  submitForm,
  destroyContact,
  errorMessages,
  action = null,
  contact = null,
}) {
  const { t } = useTranslation('billing');

  return action ? (
    <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <BoxIcon name="bxs-contact is-size-3" />
          <span>
            <h4 className="title is-5 mb-0 ml-4 modal-card-title">
              {t(`${action} Billing Contact`)}
            </h4>
          </span>
        </header>
        <section className="modal-card-body">
          <div className="field is-grouped">
            <div className="control is-expanded">
              <FieldContainer name="name" required>
                <Field
                  id="name"
                  name="name"
                  required
                  placeholder={t('Contact Name', { ns: 'formfields' })}
                  className="input"
                />
              </FieldContainer>
            </div>
            <div className="control is-expanded">
              <FieldContainer name="title">
                <Field
                  id="title"
                  name="title"
                  placeholder={t('Title Name', { ns: 'formfields' })}
                  className="input"
                />
              </FieldContainer>
            </div>
          </div>
          <div className="field">
            <div className="control is-expanded">
              <FieldContainer name="email">
                <Field
                  id="email"
                  name="email"
                  placeholder={t('Email', { ns: 'formfields' })}
                  className="input"
                />
              </FieldContainer>
            </div>
          </div>
          <div className="field is-grouped">
            <div className="control is-expanded">
              <PhoneField />
            </div>
            <div className="control is-expanded">
              <FieldContainer name="extension">
                <FastField
                  id="extension"
                  name="extension"
                  placeholder={t('Ext', { ns: 'formfields' })}
                  className="input"
                />
              </FieldContainer>
            </div>
          </div>
          <ServerErrors errorMessages={errorMessages} />
        </section>
        <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
          <button className="button is-dark" type="button" onClick={closeModal}>
            {t('Close', { ns: 'common' })}
          </button>
          {action === 'Edit' && (
            <button
              className="button is-danger is-outlined"
              type="button"
              onClick={() => destroyContact(contact)}
            >
              {t('Delete', { ns: 'common' })}
            </button>
          )}
          <button
            className="button is-primary"
            type="button"
            onClick={submitForm}
          >
            {t(action === 'New' ? 'Create' : 'Update', { ns: 'common' })}
          </button>
        </footer>
      </div>
    </div>
  ) : null;
}

BillingContactFormModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  action: PropTypes.string,
  submitForm: PropTypes.func.isRequired,
  destroyContact: PropTypes.func.isRequired,
  contact: PropTypes.object,
  errorMessages: PropTypes.array.isRequired,
};

export default flowRight(
  withFormWrap({ initialValues, schema }),
  withUpdate,
  withCreate,
  withFormik({
    mapPropsToValues: (props) => props.initialValues(props),
    validationSchema: (props) => props.schema(),
    enableReinitialize: true,
    handleSubmit: (values, { props, setSubmitting }) => {
      const variables = {
        variables: { input: { input: values, clientMutationId: uuidv4() } },
      };

      if (props.action === 'New') {
        props.create(variables);
      } else {
        props.update(variables);
      }

      setSubmitting(false);
    },
  }),
)(BillingContactFormModal);
