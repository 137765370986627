import { FormWrapper } from '../FormContainer';

const withFormWrap = (injectedProps) => (WrappedComponent) => {
  function WithFormWrap(props) {
    return (
      <FormWrapper
        {...injectedProps}
        {...props}
        render={(rest) => <WrappedComponent {...rest} />}
      />
    );
  }

  return WithFormWrap;
};

export default withFormWrap;
