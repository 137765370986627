import PropTypes from 'prop-types';
import { useState, useEffect, memo, useContext } from 'react';
import { withRouter } from 'react-router';
import { CurrentUserContext } from '../../Context';
import createNotification from '../../HelperFunctions/createNotification';

function FormWrapper(props) {
  const { match, initialValues, schema, refreshCurrentUser } = props;
  const { params } = match;
  const { action, slug, type, root } = params;
  const [{ errorMessages, formSuccess, successMessages }, setState] = useState({
    errorMessages: [],
    successMessages: [],
    formSuccess: false,
  });
  const { setCurrentUser } = useContext(CurrentUserContext);
  useEffect(() => {
    if (refreshCurrentUser && setCurrentUser) {
      setCurrentUser(true);
    }
  }, [errorMessages, formSuccess]);
  function setErrorMessages(messages) {
    setState((state) => ({ ...state, errorMessages: messages }));
    messages.map((singleMessage) => {
      createNotification.createNotification({
        type: 'error',
        message: `${singleMessage.path}: ${singleMessage.message}`,
      });
      return null;
    });
  }

  function clearErrorMessages(disableNotification) {
    const rootOrType = type || root;

    if (!disableNotification && rootOrType) {
      let text;
      if (slug) {
        text = slug;
      } else if (rootOrType.endsWith('ies')) {
        text = rootOrType.replace(/ies([^ies]*)$/, 'y$1');
      } else if (rootOrType.endsWith('s')) {
        text = rootOrType.slice(0, -1);
      } else {
        text = rootOrType;
      }
      text = text.replace(/-/g, ' ');
      const capitalizeText = text[0].toUpperCase() + text.slice(1);

      createNotification.createNotification({
        type: 'success',
        message:
          action === 'new'
            ? `${capitalizeText} was successfully created!`
            : `${capitalizeText} was successfully updated!`,
      });
    }
    setState((state) => ({ ...state, errorMessages: [], formSuccess: true }));
  }

  function getVariables() {
    if (action === 'edit' && slug) {
      return { slug };
    }
    return {};
  }

  function skipQuery() {
    return action === 'new';
  }

  const { render: renderProp, ...rest } = props;

  return renderProp({
    errorMessages,
    successMessages,
    setErrorMessages,
    // setSuccessMessages: setSuccessMessages,
    clearErrorMessages,
    getVariables,
    skipQuery,
    formSuccess,
    initialValues,
    schema,
    ...rest,
  });
}

FormWrapper.propTypes = {
  render: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
      slug: PropTypes.string,
      type: PropTypes.string,
      root: PropTypes.string,
    }),
  }),
  initialValues: PropTypes.func.isRequired,
  schema: PropTypes.func.isRequired,
  refreshCurrentUser: PropTypes.bool,
};

FormWrapper.defaultProps = {
  refreshCurrentUser: false,
  match: {
    params: {
      action: 'new',
      slug: 'null',
    },
  },
};

export default withRouter(memo(FormWrapper));
// FormWrapper.contextType = CurrentUserContext;
