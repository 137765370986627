import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { gridDateComparator, timestampFormatter } from '../../../../transforms';
import { AgGridLoader } from '../../../shared';
import '../../../shared/AgGrid/ag-grid-css-overrides.css';
import '../../../shared/AgGrid/ag-theme-balham-overrides.css';
import BillingGridFunctions from './BillingGridFunctions';

function BillingGrid({
  gridOptions,
  rowData,
  containerHeight = 400,
  onSelectionChanged = null,
}) {
  const { t } = useTranslation('aggrid');

  const history = useHistory();

  const { frameworkComponents, columnDefs, context, ...gridRest } = gridOptions;

  const translateHeaders = (headers) =>
    headers.map((header) => {
      if (header.children) {
        return {
          ...header,
          headerName: t(header.headerName),
          children: translateHeaders(header.children),
        };
      }
      return {
        ...header,
        headerName: t(header.headerName),
      };
    });

  return (
    <BillingGridFunctions>
      {({
        onGridReady,
        onGridSizeChanged,
        onFirstDataRendered,
        onColumnVisible,
      }) => (
        <div
          className="ag-theme-balham mainGridContainer ordersGridContainer"
          style={{
            height: containerHeight,
          }}
        >
          <AgGridReact
            gridOptions={{
              ...gridRest,
              localeTextFunc: (_key, defaultValue) => t(defaultValue),
            }}
            modules={AllModules}
            headerHeight={40}
            rowHeight={44}
            rowData={rowData}
            animateRows
            columnDefs={translateHeaders(columnDefs)}
            reactNext
            context={{
              history,
              ...context,
            }}
            frameworkComponents={{
              customLoadingOverlay: AgGridLoader,
              ...frameworkComponents,
            }}
            loadingOverlayComponent="customLoadingOverlay"
            floatingFiltersHeight={46}
            onGridReady={onGridReady}
            onGridSizeChanged={onGridSizeChanged}
            onFirstDataRendered={onFirstDataRendered}
            onColumnVisible={onColumnVisible}
            groupHeaderHeight={30}
            onSelectionChanged={onSelectionChanged}
            statusBar={{
              statusPanels: [
                {
                  statusPanel: 'agTotalAndFilteredRowCountComponent',
                  align: 'left',
                },
              ],
            }}
            defaultColDef={{
              resizable: true,
              editable: false,
              suppressMenu: false,
              sortable: true,
              filterParams: { newRowsAction: 'keep' },
              floatingFilter: true,
            }}
            columnTypes={{
              dateColumn: {
                width: 185,
                minWidth: 185,
                filter: 'agDateColumnFilter',
                filterParams: {
                  newRowsAction: 'keep',
                  comparator: (filterLocalDateAtMidnight, cellValue) =>
                    gridDateComparator(filterLocalDateAtMidnight, cellValue),
                },
                valueFormatter: (params) => {
                  if (params.value) {
                    return timestampFormatter(params.value, false);
                  }

                  return params.value;
                },
              },
            }}
            sideBar={{
              toolPanels: [
                {
                  id: 'columns',
                  labelDefault: 'Show / Hide Columns',
                  labelKey: 'columns',
                  iconKey: 'columns',
                  toolPanel: 'agColumnsToolPanel',
                  toolPanelParams: {
                    suppressPivotMode: true,
                    suppressPivots: true,
                    suppressRowGroups: true,
                    suppressValues: true,
                  },
                },
                {
                  id: 'filters',
                  labelDefault: 'Filters',
                  labelKey: 'filters',
                  iconKey: 'filter',
                  toolPanel: 'agFiltersToolPanel',
                },
              ],
            }}
          />
        </div>
      )}
    </BillingGridFunctions>
  );
}

BillingGrid.propTypes = {
  gridOptions: PropTypes.object.isRequired,
  rowData: PropTypes.array.isRequired,
  containerHeight: PropTypes.number,
  onSelectionChanged: PropTypes.func,
};

export default BillingGrid;
