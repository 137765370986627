import { graphql } from '@apollo/client/react/hoc';
import { CREATE_DEBIT } from '@fullcontour/shared-api';

const withCreate = graphql(CREATE_DEBIT, {
  name: 'create',
  options: (props) => ({
    onCompleted: (data) => {
      const { createDebit } = data;
      if (createDebit.errorMessages.length) {
        props.setErrorMessages(createDebit.errorMessages);
      } else {
        props.clearErrorMessages();
        props.refetch();
        props.closeModal();
      }
    },
  }),
});

export default withCreate;
