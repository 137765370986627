import { Field, withFormik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import 'react-credit-cards/es/styles-compiled.css';
import { v4 as uuidv4 } from 'uuid';
import { FieldContainer, ServerErrors, withFormWrap } from '../../../../shared';
import { initialValues, schema, withCreate } from './helpers';

function BillingInvoicePostPaymentFormModal({
  modalOpen,
  closeModal,
  submitForm,
  resetForm,
  maxAmount,
  errorMessages,

  errors = {},
  touched = {},
}) {
  const resetAndCloseModal = () => {
    resetForm();
    closeModal();
  };

  return (
    <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <h4 className="title is-5 mb-0 ml-4 modal-card-title">
            Post A Payment
          </h4>
        </header>
        <section className="modal-card-body">
          <div className="field is-grouped">
            <div className="control is-expanded has-icons-left">
              <FieldContainer
                name="paymentAmount"
                label="Payment Amount"
                required
              >
                <div
                  className={`icon is-left has-text-white ${
                    errors.amount && touched.amount
                      ? 'has-background-danger'
                      : 'has-background-success'
                  }`}
                  style={{ width: 40, borderRadius: '10% 0 0 10%' }}
                >
                  $
                </div>
                <Field
                  id="paymentAmount"
                  name="paymentAmount"
                  type="number"
                  step="0.01"
                  placeholder="0.00"
                  className="input pl-6"
                  max={maxAmount}
                />
              </FieldContainer>
            </div>
            <div className="control is-expanded">
              <FieldContainer
                name="cardBilled"
                label="Payment Method Info"
                required
              >
                <Field
                  id="cardBilled"
                  name="cardBilled"
                  required
                  placeholder="i.e. Check #1234"
                  className="input"
                />
              </FieldContainer>
            </div>
          </div>
          <div className="field">
            <div className="control">
              <FieldContainer name="message" required>
                <Field
                  id="message"
                  name="message"
                  component="textarea"
                  rows="2"
                  required
                  placeholder="Payment Memo"
                  className="textarea"
                />
              </FieldContainer>
            </div>
          </div>
          <ServerErrors errorMessages={errorMessages} />
        </section>
        <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
          <button
            className="button is-dark"
            type="button"
            onClick={resetAndCloseModal}
          >
            Close
          </button>
          <button
            className="button is-primary"
            type="button"
            onClick={submitForm}
          >
            Post Payment
          </button>
        </footer>
      </div>
    </div>
  );
}

BillingInvoicePostPaymentFormModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  submitForm: PropTypes.func.isRequired,
  errorMessages: PropTypes.array.isRequired,
  resetForm: PropTypes.func.isRequired,
  maxAmount: PropTypes.number.isRequired,
};

export default flowRight(
  withFormWrap({ initialValues, schema }),
  withCreate,
  withFormik({
    mapPropsToValues: (props) => props.initialValues(props),
    validationSchema: (props) => props.schema(props),
    enableReinitialize: true,
    handleSubmit: (values, { props, setSubmitting }) => {
      const variables = {
        variables: { input: { input: values, clientMutationId: uuidv4() } },
      };

      props.create(variables);

      setSubmitting(false);
    },
  }),
)(BillingInvoicePostPaymentFormModal);
