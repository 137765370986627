import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { ResponsiveContainer } from '../layouts';
import { AdminMessageModal } from '../views';

function ProtectedRoute({
  children,
  isSignedIn,
  path,
  bodyElement = null,
  guidedFlow = null,
}) {
  return (
    <Route
      path={['/', path]}
      render={() =>
        isSignedIn && (
          <>
            <AdminMessageModal />
            <ResponsiveContainer
              bodyElement={bodyElement}
              guidedFlow={guidedFlow}
            >
              {children}
            </ResponsiveContainer>
          </>
        )
      }
    />
  );
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  bodyElement: PropTypes.object,
  guidedFlow: PropTypes.func,
};

export default ProtectedRoute;
