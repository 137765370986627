import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { statusColor } from '../../../../../../transforms';

class GridCellStatus extends Component {
  constructor(props) {
    super(props);

    const { data, value } = this.props;

    this.state = {
      color: statusColor(value, data.isRush),
    };
  }

  render() {
    const { value, data, t } = this.props;
    const { color } = this.state;

    return (
      <div style={{ paddingTop: 4 }}>
        <h6
          className={`title is-6 has-text-${color} is-capitalized`}
          style={{
            lineHeight: 'initial',
            fontWeight: '700',
            fontSize: '0.85rem',
            marginBottom: 4,
          }}
        >
          {t(value.replace(/_/gi, ' '))}
        </h6>
        <progress
          className={`progress is-${color} is-small`}
          value={data.percentComplete}
          max="100"
        />
      </div>
    );
  }
}

GridCellStatus.propTypes = {
  value: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('navigation')(GridCellStatus);
