import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';

class GridCellApproval extends Component {
  invokeParent = () => {
    const { context, data } = this.props;
    context.componentParent.reviewCase(data);
  };

  render() {
    const { value, data, t } = this.props;
    const app = import.meta.env.VITE_APP_NAME;

    if (
      (data.state === 'sent_to_manufacturer' ||
        data.state === 'at_manufacturer' ||
        data.state === 'shipped' ||
        data.state === 'ready_for_download') &&
      value === true &&
      app !== 'manuf_portal' &&
      app !== 'designer_portal'
    ) {
      return (
        <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
          <button
            type="button"
            className="button is-small is-success"
            onClick={this.invokeParent}
          >
            {t('Review Case')}...
          </button>
        </div>
      );
    }

    return <div>{t(value ? 'Approval needed' : 'No approval needed')}</div>;
  }
}

GridCellApproval.propTypes = {
  value: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  context: PropTypes.object,
};

GridCellApproval.defaultProps = {
  context: {},
};

export default withTranslation('aggrid')(GridCellApproval);
