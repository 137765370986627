import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { typeName } from '../../../../transforms';

function FormHeader({ customMessage = null }) {
  const { root, type, action } = useParams();
  const { t } = useTranslation('formheaders');

  const headerName = customMessage
    ? `${action || ''} ${customMessage}`
    : `${action} ${
        type
          ? `${type
              .replace('ies', 'y')
              .replace(/(?:s|[sx])$/, '')
              .replace(/-/g, ' ')} `
          : ''
      }${typeName(root, type)}`;

  return (
    <h4
      className="title is-4 m-0 has-text-primary"
      style={{ textTransform: 'capitalize', lineHeight: '40px' }}
    >
      {t(headerName)}
    </h4>
  );
}

FormHeader.propTypes = {
  customMessage: PropTypes.string,
};

export default FormHeader;
