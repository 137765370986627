import { Field, withFormik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { classesGenerator } from '../../../../../../transforms';
import { FieldContainer, withFormWrap } from '../../../../../shared';
import { Steps } from '../../../../../shared/Components';
import { LocationDesignGuideSelect } from '../../components';
import { initialValues, schema, withCreate } from './helpers';

function LocationGuideGroupModal(props) {
  const { modal, setModal, values, submitForm, setFieldValue } = props;

  const modalClasses = useMemo(
    () =>
      classesGenerator({
        'is-active': modal,
        modal: true,
      }),
    [modal],
  );

  const onChange = (data, category) => {
    const { guideCodeIds } = values;
    if (category.showDropdown) {
      category.guideCodes.map((item) => {
        guideCodeIds[item.id] = false;
        return null;
      });
    }

    guideCodeIds[data.id] = !guideCodeIds[data.id];

    setFieldValue('guideCodeIds', guideCodeIds);
  };

  return (
    <div className={modalClasses}>
      <div
        className="modal-background"
        onClick={() => setModal(false)}
        onKeyDown={() => setModal(false)}
        role="button"
        tabIndex={0}
        aria-label="close"
      />
      <div
        className="modal-card"
        style={{ maxWidth: 1100, width: '100%', padding: '100px 0' }}
      >
        <section className="modal-card-body pb-0 pt-0">
          <Steps
            stepsBorder
            lastButtonText="Create"
            callback={submitForm}
            modal={modal}
            setModal={setModal}
          >
            <Steps.Step title="Create new group" validate={['name']}>
              <FieldContainer required name="Group name">
                <Field
                  name="name"
                  className="input"
                  type="string"
                  placeholder="Name..."
                />
              </FieldContainer>
            </Steps.Step>
            <Steps.Step title="Add codes to the group">
              <LocationDesignGuideSelect
                onChange={onChange}
                selectedCodes={values.guideCodeIds}
                noOther
              />
            </Steps.Step>
          </Steps>
        </section>
      </div>
    </div>
  );
}

LocationGuideGroupModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  values: PropTypes.shape({
    guideCodeIds: PropTypes.object,
  }).isRequired,
};

export default flowRight(
  withFormWrap({ initialValues, schema }),
  withCreate,
  withFormik({
    mapPropsToValues: (props) => props.initialValues(props),
    validationSchema: (props) => props.schema(props),
    enableReinitialize: true,
    handleSubmit: (values, { props, setSubmitting }) => {
      const { guideCodeIds } = values;
      const codeIds = [];
      Object.keys(guideCodeIds).forEach((key) => {
        if (guideCodeIds[key]) {
          codeIds.push(key);
        }
      });
      const variables = {
        variables: {
          input: {
            input: {
              locationSlug: props.match.params.slug,
              name: values.name,
              guideCodeIds: codeIds,
            },
            clientMutationId: uuidv4(),
          },
        },
      };

      props.create(variables);

      setSubmitting(false);
    },
  }),
)(LocationGuideGroupModal);
