import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import useTranslatedPathname from './useTranslatedPathname';

function BreadCrumbPathsMiddle(props) {
  const { p, i, arr } = props;
  const params = useParams();
  const pathname = useTranslatedPathname(params, p);

  return (
    <li className={`${i === arr.length - 1 ? 'is-active' : ''}`}>
      <NavLink
        style={{ textTransform: 'capitalize' }}
        exact
        to={`${arr.slice(0, i + 1).join('/')}`}
      >
        {pathname}
      </NavLink>
    </li>
  );
}

BreadCrumbPathsMiddle.propTypes = {
  p: PropTypes.string.isRequired,
  i: PropTypes.number.isRequired,
  arr: PropTypes.array.isRequired,
};

export default BreadCrumbPathsMiddle;
