import { lazy } from 'react';

const BasicProfileForm = lazy(
  () => import('../../views/Profile/BasicProfile/BasicProfileForm'),
);

const NotificationsForm = lazy(
  () =>
    import('../../views/Profile/NotificationSettings/NotificationSettingsForm'),
);

const UpdatePasswordForm = lazy(
  () => import('../../views/Profile/UpdatePassword/UpdatePasswordForm'),
);

const routes = [
  {
    path: '/:root(profile)',
    component: BasicProfileForm,
    exact: true,
  },
  {
    path: '/:root(profile)/notifications',
    component: NotificationsForm,
    exact: true,
  },
  {
    path: '/:root(profile)/update-password',
    component: UpdatePasswordForm,
    exact: true,
  },
];

export default routes;
