import { graphql } from '@apollo/client/react/hoc';
import { CREATE_CREDIT } from '@fullcontour/shared-api';

const withCreate = graphql(CREATE_CREDIT, {
  name: 'create',
  options: (props) => ({
    onCompleted: (data) => {
      const { createCredit } = data;
      if (createCredit.errorMessages.length) {
        props.setErrorMessages(createCredit.errorMessages);
      } else {
        props.clearErrorMessages();
        props.refetch();
        props.closeModal();
      }
    },
  }),
});

export default withCreate;
