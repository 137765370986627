import { graphql } from '@apollo/client/react/hoc';
import { CREATE_CREDIT_CARD } from '@fullcontour/shared-api';

const withCreate = graphql(CREATE_CREDIT_CARD, {
  name: 'create',
  options: (props) => ({
    onCompleted: (data) => {
      const { createCreditCard } = data;
      if (createCreditCard.errorMessages.length) {
        props.setErrorMessages(createCreditCard.errorMessages);
      } else {
        props.clearErrorMessages();
        props.refetch();
        props.closeModal();
      }
    },
  }),
});

export default withCreate;
