import { lazy } from 'react';

const OrderShow = lazy(() => import('../../views/Orders/OrderShow/OrderShow'));

const routes = [
  {
    path: '/:root(orders)/:type(details)/:id',
    component: OrderShow,
    exact: false,
  },
];

export default routes;
