import { Suspense, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Placeholder } from '../shared';
import { CurrentUserContext, RouteContext } from '../shared/Context';
import legalSidebarRoutes from './sidebar/legalSidebarRoutes';
import orderDetailsSidebarRoutes from './sidebar/orderDetailsSidebarRoutes';

function SidebarNavRoutes() {
  const { sidebarRoutes } = useContext(RouteContext);
  const { currentUser } = useContext(CurrentUserContext);

  const scopedRoutes = sidebarRoutes.filter((route) => {
    if (currentUser?.scopeToLocation) {
      return route.sl === true;
    }

    return route.so === true;
  });

  const combinedRoutes = [
    ...scopedRoutes,
    ...orderDetailsSidebarRoutes,
    ...legalSidebarRoutes,
  ];

  return (
    <Suspense
      fallback={
        <>
          <Placeholder height="40px" className="mb-1" />
          <Placeholder height="40px" className="mb-1" />
          <Placeholder height="40px" className="mb-1" />
        </>
      }
    >
      <Switch>
        {combinedRoutes.map((route) => (
          <Route key={route.path} exact={route.exact} path={route.path}>
            <route.component />
          </Route>
        ))}

        <Redirect from="/orders/details" to="/orders" exact />
        {/* <Redirect from="/" to="/orders" exact /> */}
      </Switch>
    </Suspense>
  );
}

export default SidebarNavRoutes;
