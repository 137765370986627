import * as Yup from 'yup';

function schema() {
  return Yup.object({
    id: Yup.string().required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string(),
    preferredUsername: Yup.string().required('Required'),
    language: Yup.string().required('Required'),
    phone: Yup.string(),
  });
}

export default schema;
