import { useApolloClient } from '@apollo/client';
import {
  GET_GLOBAL_SEARCH,
  GET_GLOBAL_SEARCH_CUSTOMER,
  GET_GLOBAL_SEARCH_DESIGNER,
  GET_GLOBAL_SEARCH_MANUF,
} from '@fullcontour/shared-api';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Search } from '../../shared';
import './GlobalSearch.css';
import GlobalSearchResult from './GlobalSearchResult';

function GlobalSearch({ app }) {
  const history = useHistory();
  const client = useApolloClient();

  const query = useMemo(() => {
    switch (app) {
      case 'admin_portal':
        return { queryName: GET_GLOBAL_SEARCH, dataName: 'globalSearch' };
      case 'customer_portal':
        return {
          queryName: GET_GLOBAL_SEARCH_CUSTOMER,
          dataName: 'globalSearchCustomer',
        };
      case 'designer_portal':
        return {
          queryName: GET_GLOBAL_SEARCH_DESIGNER,
          dataName: 'globalSearchDesigner',
        };
      case 'manuf_portal':
        return {
          queryName: GET_GLOBAL_SEARCH_MANUF,
          dataName: 'globalSearchManufacturer',
        };
      default:
        return null;
    }
  }, [app]);

  const [globalSearchState, setGlobalSearchState] = useState({
    loading: false,
    results: [],
    value: '',
  });

  const { loading, results, value } = globalSearchState;

  useEffect(() => {
    async function performQuery() {
      const { queryName, dataName } = query;
      const { data } = await client.query({
        query: queryName,
        variables: { q: value },
        fetchPolicy: 'no-cache',
      });
      const formattedData = data[dataName].map((search) => {
        const { attributes } = search;
        return attributes;
      });
      setGlobalSearchState((prevState) => ({
        ...prevState,
        loading: false,
        results: formattedData,
      }));
    }

    const timer = setTimeout(() => {
      if (value.length < 1) {
        return setGlobalSearchState({
          loading: false,
          results: [],
          value: '',
        });
      }
      return performQuery();
    }, 500);
    return () => clearTimeout(timer);
  }, [client, value, query]);

  async function handleSearchChange(val) {
    setGlobalSearchState((prevState) => ({
      ...prevState,
      loading: true,
      value: val,
    }));
  }

  function handleResultSelect(result) {
    if (result.klass === 'Order') {
      history.push(`/orders/details/${result.title}`);
    }

    if (app === 'admin_portal') {
      if (result.klass === 'LabLocation') {
        history.push(`/locations/customer/${result.slug}`);
      }

      if (result.klass === 'LabOrganization') {
        history.push(`/organizations/customer/${result.slug}`);
      }

      if (result.klass === 'ManufacturerOrganization') {
        history.push(`/organizations/manufacturer/${result.slug}`);
      }
    }
  }

  return (
    <Search
      labelStyle={{
        display: 'none',
      }}
      size="mini"
      id="globalSearchInput"
      category
      loading={loading}
      results={results}
      resultRenderer={GlobalSearchResult}
      value={value}
      minCharacters={1}
      onResultSelect={handleResultSelect}
      onSearchChange={debounce(handleSearchChange, 500, {
        leading: true,
      })}
    />
  );
}
GlobalSearch.propTypes = {
  app: PropTypes.string.isRequired,
};

export default GlobalSearch;
