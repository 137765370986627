import { Field, withFormik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import 'react-credit-cards/es/styles-compiled.css';
import { v4 as uuidv4 } from 'uuid';
import { ServerErrors, withFormWrap } from '../../../../shared';
import { initialValues, schema, withUpdate } from './helpers';

function BillingInvoiceMemoFormModal(props) {
  const { modalOpen, closeModal, submitForm, resetForm, errorMessages } = props;

  const resetAndCloseModal = () => {
    resetForm();
    closeModal();
  };

  return (
    <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <h4 className="title is-5 mb-0 ml-4 modal-card-title">Update Memo</h4>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <div className="control">
              <Field
                id="memo"
                name="memo"
                component="textarea"
                rows="4"
                required
                placeholder="Memo"
                className="textarea"
              />
            </div>
          </div>
          <ServerErrors errorMessages={errorMessages} />
        </section>
        <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
          <button
            className="button is-dark"
            type="button"
            onClick={resetAndCloseModal}
          >
            Close
          </button>
          <button
            className="button is-primary"
            type="button"
            onClick={submitForm}
          >
            Update
          </button>
        </footer>
      </div>
    </div>
  );
}

BillingInvoiceMemoFormModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  errorMessages: PropTypes.array.isRequired,
  resetForm: PropTypes.func.isRequired,
};

export default flowRight(
  withFormWrap({ initialValues, schema }),
  withUpdate,
  withFormik({
    mapPropsToValues: (props) => props.initialValues(props),
    validationSchema: (props) => props.schema(props),
    enableReinitialize: true,
    handleSubmit: (values, { props, setSubmitting }) => {
      const variables = {
        variables: { input: { input: values, clientMutationId: uuidv4() } },
      };

      props.update(variables);

      setSubmitting(false);
    },
  }),
)(BillingInvoiceMemoFormModal);
