import { useApolloClient, useQuery } from '@apollo/client';
import { GET_ORDER } from '@fullcontour/shared-api';
import { lazy, Suspense } from 'react';
import { Route, Switch, useParams } from 'react-router';
import { BaseLoader, LoadingOrError } from '../../../shared';

const OrderShowMessages = lazy(
  () => import('./OrderShowMessages/OrderShowMessages'),
);
const OrderShowMessagesCustomer = lazy(
  () => import('./OrderShowMessages/OrderShowMessagesCustomer'),
);

const OrderShowInfo = lazy(() => import('./OrderShowInfo/OrderShowInfo'));

const OrderShowActivity = lazy(
  () => import('./OrderShowActivity/OrderShowActivity'),
);

const OrderShowPreferencesNew = lazy(
  () => import('./OrderShowPreferences/OrderShowPreferencesNew'),
);

const OrderShowFiles = lazy(() => import('./OrderShowFiles/OrderShowFiles'));

const OrderShowActors = lazy(() => import('./OrderShowActors/OrderShowActors'));

function OrderShow() {
  const { id } = useParams();
  const client = useApolloClient();

  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: { id },
  });

  if (loading || error)
    return <LoadingOrError error={error} loading={loading} title="Case" />;

  const { order } = data;

  const isCustomerPortal = import.meta.env.VITE_APP_NAME === 'customer_portal';
  const showCustomerActivity =
    import.meta.env.VITE_APP_NAME === 'admin_portal' ||
    import.meta.env.VITE_APP_NAME === 'manuf_portal';

  return (
    <Suspense fallback={<BaseLoader loading />}>
      <Switch>
        <Route exact path="/orders/details/:id">
          <OrderShowFiles order={order} />
        </Route>
        <Route exact path="/orders/details/:id/order-info">
          <OrderShowInfo order={order} client={client} />
        </Route>
        <Route exact path="/orders/details/:id/design-preferences">
          <OrderShowPreferencesNew
            feedbacks={order.feedbacks}
            jobCode={order.jobCode}
            orderItems={order.orderItems}
            customInstructions={order.location.customInstructions}
            keepExistingCustomInstructions={
              order.location.keepExistingCustomInstructions
            }
            redesignCount={order.redesignCount}
            preferences={order.customerDesignPreferences}
            instructions={order.instructions}
            locationId={order.location.id}
            modifiers={order.itemsMetadata.modifiers}
            orderId={order.id}
            hexOrderCode={order.hexOrderCode}
            designTypeInstructions={order.location.designTypeInstructions}
            allowNested={order.location.allowNested}
            numberingSystem={order.location.numberingSystem}
          />
        </Route>

        {showCustomerActivity && (
          <Route exact path="/orders/details/:id/customer-activity">
            <OrderShowMessagesCustomer locationId={order.location.id} />
          </Route>
        )}
        <Route exact path="/orders/details/:id/activity">
          {isCustomerPortal ? (
            <OrderShowMessagesCustomer />
          ) : (
            <OrderShowActivity />
          )}
        </Route>
        {!isCustomerPortal &&
        import.meta.env.VITE_APP_NAME !== 'designer_portal' ? (
          <Route exact path="/orders/details/:id/messages">
            <OrderShowMessages />
          </Route>
        ) : null}

        <Route exact path="/orders/details/:id/actors">
          <OrderShowActors order={order} />
        </Route>
      </Switch>
    </Suspense>
  );
}

export default OrderShow;
