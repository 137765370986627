import { GET_CUSTOMER_LOC_DESIGN_PREFS } from '@fullcontour/shared-api';
import { FieldArray, Formik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  FormGridWrap,
  FormSection,
  LoadingOrError,
  withFormWrap,
} from '../../../shared';
import FieldArrayInner from './FormElements/FieldArrayInner';
import { initialValues, schema, withUpdate } from './helpers';

function LocationCustomerDesignPreferencesFieldArray({
  initialValues = () => {},
  schema = () => {},
  match = {},
  update = () => {},
  currentUser = {},
  ...rest
}) {
  const { edit, toggleEdit } = rest;
  const { data, loading, error } = useQuery(GET_CUSTOMER_LOC_DESIGN_PREFS, {
    variables: (() => {
      const app = import.meta.env.VITE_APP_NAME;
      if (app === 'customer_portal' && currentUser.scopeToLocation) {
        return {};
      }
      return { slug: match.params.slug };
    })(),
    fetchPolicy: 'cache-and-network',
  });
  if (loading || error)
    return (
      <LoadingOrError
        error={error}
        loading={loading}
        title="Customer Location Design Preferences"
      />
    );
  function handleSubmit(values, setSubmitting) {
    const updatedValues = {
      designProfiles: values.designProfiles,
      customInstructions: values.customInstructions,
      keepExistingCustomInstructions: values.keepExistingCustomInstructions,
      id: values.id,
      designTypeInstructions: values.designTypeInstructions.map((item) => ({
        instruction: item.instruction,
        designTypeId: item.value,
      })),
    };
    const variables = {
      variables: { input: { input: updatedValues } },
    };
    update(variables);
    setSubmitting(false);
  }
  return (
    <Formik
      initialValues={initialValues({ data })}
      validationSchema={schema()}
      // enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting);
      }}
    >
      {({ values, setFieldValue }) => (
        <FormSection title="Design Preferences">
          <FieldArray
            name="designProfiles"
            render={(arrayHelpers) => (
              <FormGridWrap hideHeader>
                <FieldArrayInner
                  edit={edit}
                  toggleEdit={toggleEdit}
                  arrayHelpers={arrayHelpers}
                  {...{
                    ...rest,
                    initialValues,
                    schema,
                    match,
                    update,
                    currentUser,
                    data,
                    values,
                    setFieldValue,
                  }}
                />
              </FormGridWrap>
            )}
          />
        </FormSection>
      )}
    </Formik>
  );
}

LocationCustomerDesignPreferencesFieldArray.propTypes = {
  initialValues: PropTypes.func,
  schema: PropTypes.func,
  match: PropTypes.object,
  update: PropTypes.func,
  currentUser: PropTypes.object,
};

export default flowRight(
  withFormWrap({ initialValues, schema }),
  withUpdate,
)(LocationCustomerDesignPreferencesFieldArray);
