import '@fortawesome/fontawesome-free/css/all.min.css';
import {
  AuthLayout,
  ForgotPassword,
  ResetPassword,
  SignIn,
} from '@fullcontour/auth';
import NewPassword from '@fullcontour/auth/src/views/NewPassword';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Redirect, Route, Switch } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import 'yet-another-react-lightbox/styles.css';
import ProtectedRoute from '../../routing/ProtectedRoute';
import { LanguageProvider, RouteProvider } from '../Context';
import { ErrorPages } from '../Errors';
import HelpCenterBar from '../HelpCenterBar/HelpCenterBar';
import LegalHOC from '../LegalHOC/LegalHOC';

function AppHoc({
  children,
  app,
  values,
  routing = {},
  bodyElement = null,
  guidedFlow = null,
}) {
  const {
    tempCredentials,
    login,
    forgotPassword,
    forgotPasswordSubmit,
    changePassword,
    currentUser,
    isSignedIn,
    logOut,
    lastLocation,
    error,
    cleanError,
  } = values;
  const { rootPaths } = routing;
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState(
    window.localStorage.getItem('defaultLanguage') || 'en',
  );

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [i18n, locale]);

  function changeDefaultLanguage(value) {
    setLocale(value);
  }
  return (
    <LanguageProvider value={{ locale, changeDefaultLanguage }}>
      <RouteProvider value={routing}>
        <Switch>
          <LegalHOC>
            <Route
              exact
              path="/"
              render={() => (
                <AuthLayout app={app}>
                  <SignIn
                    login={login}
                    currentUser={currentUser}
                    error={error}
                    cleanError={cleanError}
                  />
                </AuthLayout>
              )}
            />

            <Route
              exact
              path="/signin"
              render={() => (
                <AuthLayout app={app}>
                  <SignIn
                    login={login}
                    currentUser={currentUser}
                    app={app}
                    lastLocation={lastLocation}
                    error={error}
                    cleanError={cleanError}
                  />
                </AuthLayout>
              )}
            />
            <Route
              exact
              path="/forgot-password"
              render={() => (
                <AuthLayout app={app} newPassword>
                  <ForgotPassword
                    error={error}
                    forgotPassword={forgotPassword}
                    cleanError={cleanError}
                  />
                </AuthLayout>
              )}
            />
            <Route
              exact
              path="/new-password"
              render={() => (
                <AuthLayout app={app} newPassword>
                  <NewPassword
                    changePassword={changePassword}
                    tempCredentials={tempCredentials}
                    currentUser={currentUser}
                    logOut={logOut}
                    cleanError={cleanError}
                    error={error}
                    login={login}
                  />
                </AuthLayout>
              )}
            />
            <Route
              exact
              path="/reset-password"
              render={() => (
                <AuthLayout app={app} newPassword>
                  <ResetPassword
                    resetPassword={forgotPasswordSubmit}
                    forgotPassword={forgotPassword}
                    tempCredentials={tempCredentials}
                    cleanError={cleanError}
                    error={error}
                    login={login}
                  />
                </AuthLayout>
              )}
            />

            {!!currentUser && isSignedIn && (
              <>
                <ProtectedRoute
                  path={rootPaths[Number(currentUser?.scopeToLocation)]}
                  isSignedIn={isSignedIn}
                  bodyElement={bodyElement}
                  guidedFlow={guidedFlow}
                >
                  {children}
                </ProtectedRoute>
                {lastLocation && lastLocation === '/signin' && (
                  <Redirect to="/" />
                )}
              </>
            )}
            <Route path="/error-page/:error">
              <ErrorPages />
            </Route>
          </LegalHOC>
        </Switch>
        <HelpCenterBar />
        <NotificationContainer />
      </RouteProvider>
    </LanguageProvider>
  );
}

AppHoc.propTypes = {
  app: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  routing: PropTypes.object,
  values: PropTypes.object.isRequired,
  bodyElement: PropTypes.any,
  guidedFlow: PropTypes.any,
};

export default memo(AppHoc);
