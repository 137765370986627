import { Suspense, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { BreadCrumbContainer } from '../layouts/BreadCrumb';
import { BaseLoader } from '../shared';
import { CurrentUserContext, RouteContext } from '../shared/Context';
import sharedRoutes from './main';

function SidebarBreadcrumbRoutes() {
  const { redirects, mainRoutes } = useContext(RouteContext);
  const { currentUser } = useContext(CurrentUserContext);

  const scopedRoutes = mainRoutes.filter((route) => {
    if (currentUser?.scopeToLocation) {
      return route.sl === true;
    }

    return route.so === true;
  });

  return (
    <Suspense fallback={<BaseLoader loading />}>
      <Switch>
        {scopedRoutes.map((route) => (
          <Route key={route.path} exact path={route.path}>
            <BreadCrumbContainer />
          </Route>
        ))}

        {sharedRoutes.map((route) => (
          <Route key={route.path} exact={route.exact} path={route.path}>
            <BreadCrumbContainer />
          </Route>
        ))}

        {redirects.length > 0 &&
          redirects.map((redirect) => (
            <Redirect
              from={redirect.from}
              to={redirect.to}
              exact={redirect.exact}
              key={redirect.key}
            />
          ))}

        <Redirect from="/orders/details" to="/orders" exact />
      </Switch>
    </Suspense>
  );
}

export default SidebarBreadcrumbRoutes;
