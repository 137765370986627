import PropTypes from 'prop-types';
import { useContext } from 'react';
import Dropzone from 'react-dropzone';
import { useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { CurrentUserContext, OverpanelFilesContext } from '../../shared';
import './OrderDropzone.css';
import { activeStyle, baseStyle, rejectedStyle } from './OrderDropzoneStyle';

function OrderDropzone({
  children,
  overpanelOpen,
  accept = {
    'application/*': ['.zip', '.lab'],
  },
  helperText = 'Drop your case files here to create new cases',
  customClass = '',
}) {
  const location = useLocation();
  const { pathname } = location;

  const { currentUser, enableSimpleUploader } = useContext(CurrentUserContext);
  const { pushFiles } = useContext(OverpanelFilesContext);

  function onDrop(accepted) {
    const acceptedFiles = accepted.map((file) => ({
      file,
      isChecked: false,
      id: uuidv4(),
    }));
    pushFiles(acceptedFiles);
  }

  const splitPath = pathname.split('/');
  const lastPath = splitPath[splitPath.length - 1];
  const thirdPath = splitPath.length > 2 ? splitPath[2] : lastPath;

  return (
    <Dropzone
      accept={accept}
      multiple
      onDrop={(accepted) => onDrop(accepted)}
      disabled={
        !overpanelOpen &&
        (lastPath === 'edit' ||
          lastPath === 'new' ||
          lastPath === 'messages' ||
          thirdPath === 'details' ||
          currentUser?.scopeToLocation === false ||
          enableSimpleUploader)
      }
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
        let styles = { ...baseStyle };
        styles = isDragActive ? { ...styles, ...activeStyle } : styles;
        styles = isDragReject ? { ...styles, ...rejectedStyle } : styles;

        return (
          <div
            style={styles}
            {...getRootProps({ onClick: (evt) => evt.stopPropagation() })}
            className={
              overpanelOpen
                ? `dropzoneContainer overpanelOpen ${customClass}`
                : `dropzoneContainer overpanelClosed ${customClass}`
            }
          >
            <input {...getInputProps()} />
            {children}
            {isDragActive && (
              <div className="fullScreenDrag is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                <span
                  className="icon has-text-primary is-large"
                  style={{ fontSize: '5rem' }}
                >
                  <i className="bx bx-cloud-upload" />
                </span>

                <h4 className="title is-4 has-text-centered">{helperText}</h4>
              </div>
            )}
          </div>
        );
      }}
    </Dropzone>
  );
}

OrderDropzone.propTypes = {
  children: PropTypes.node.isRequired,
  overpanelOpen: PropTypes.bool.isRequired,
  accept: PropTypes.any,
  helperText: PropTypes.string,
  customClass: PropTypes.string,
};

export default OrderDropzone;
