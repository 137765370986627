import * as Yup from 'yup';

function schema(props) {
  return Yup.object({
    invoiceId: Yup.string().required('An Invoice is required'),
    message: Yup.string().required('A Message is required'),
    cardBilled: Yup.string(),
    isCard: Yup.boolean().required(),
    paymentAmount: Yup.number()
      .positive('Must be a valid Amount')
      .required('Required')
      .max(props.maxAmount)
      .typeError('Must be a valid Amount'),
  });
}

export default schema;
