import PropTypes from 'prop-types';

function GridCellTier({ value }) {
  return <div className={`tag location-tier-color-${value}`}>{value}</div>;
}

GridCellTier.propTypes = {
  value: PropTypes.string.isRequired,
};

export default GridCellTier;
