import PropTypes from 'prop-types';

function GridCellDelete({ data, context, column }) {
  const deleteHandler = () => {
    context.reviewCase({
      ...data,
      query: column.userProvidedColDef.query,
      successText: `You have successfully deleted ${data.name}`,
      field: column.userProvidedColDef.field,
      bodyText:
        // eslint-disable-next-line dot-notation
        data['__typename'] === 'GuideCategoryGroup'
          ? `Deleting ${
              data.name
            } will remove the Category from the following Design Types: ${data.designTypes
              .map((item) => item.name)
              .join(', ')}. Do you want to proceed?`
          : null,
    });
  };

  return (
    <div className="if-flex is-justify-content-center is-align-items-center">
      <button
        type="button"
        className="button is-danger is-outlined"
        onClick={deleteHandler}
      >
        <span className="icon is-small">
          <i className="bx bx-trash" />
        </span>
      </button>
    </div>
  );
}

GridCellDelete.propTypes = {
  data: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

export default GridCellDelete;
