import { lazy } from 'react';

const LegalPage = lazy(() => import('../../views/Legal/LegalPage'));

const routes = [
  {
    path: ['/:root(legal)', '/:root(legal)/:type(baa|privacy-policy)'],
    component: LegalPage,
    exact: true,
  },
];

export default routes;
