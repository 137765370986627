import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import './Steps.scss';

import Step from './Step';

function Steps({
  children,
  callback,
  current = 0,
  stepsBorder = false,
  setModal = () => {},
  modal = false,
  lastButtonText = 'Finish',
}) {
  const [currentActive, setCurrentActive] = useState(current);
  const [fieldsToVaidate, setFieldsToVaidate] = useState([]);
  const { errors, isSubmitting, validateForm } = useFormikContext();

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderedBody = useRef(null);

  const childrenWithProps = useMemo(
    () =>
      React.Children.map(children, (child, idx) => {
        if (React.isValidElement(child)) {
          if (idx === currentActive) {
            setFieldsToVaidate(child.props.validate || []);

            renderedBody.current = child.props.children;
          }
          return React.cloneElement(child, {
            isActive: currentActive === idx,
            currentActive,
            setCurrentActive,
            idx: idx + 1,
            last: idx === children.length - 1,
          });
        }
        return child;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modal, currentActive],
  );

  const handleNext = () => {
    if (currentActive + 1 === children.length) {
      if (callback) callback();
    } else {
      setCurrentActive((prev) => prev + 1);
    }
  };
  const handlePrev = () => {
    setCurrentActive((prev) => (prev - 1 < 0 ? 0 : prev - 1));
  };

  return (
    <div>
      <div
        style={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 60,
        }}
        className={`is-flex is-justify-content-center pt-4 pb-4 ${
          stepsBorder ? 'steps__header-border' : ''
        }`}
      >
        {childrenWithProps}
      </div>
      <div
        className={`pt-6 ${stepsBorder ? 'steps__header-border' : ''}`}
        style={{
          minHeight: 400,
        }}
      >
        {renderedBody && renderedBody.current}
      </div>
      <div
        className="is-flex pt-5 buttons"
        style={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          zIndex: 60,
          borderTop: '1px solid #ddd',
        }}
      >
        {modal && (
          <button
            type="button"
            style={{ marginRight: 'auto' }}
            className="button is-dark"
            onClick={() => setModal(false)}
          >
            Close
          </button>
        )}
        {currentActive ? (
          <button className="button is-dark" onClick={handlePrev} type="button">
            Previous
          </button>
        ) : null}

        <button
          type={currentActive === children.length - 1 ? 'submit' : 'button'}
          className={`button is-primary ${isSubmitting && 'is-loading'}`}
          onClick={handleNext}
          disabled={
            fieldsToVaidate.some((item) => errors[item]) || isSubmitting
          }
        >
          {lastButtonText && currentActive === children.length - 1
            ? lastButtonText
            : 'Next'}
        </button>
      </div>
    </div>
  );
}

Steps.Step = Step;

Steps.propTypes = {
  current: PropTypes.number,
  stepsBorder: PropTypes.bool,
  modal: PropTypes.bool,
  setModal: PropTypes.func,
  lastButtonText: PropTypes.string,
  callback: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Steps;
