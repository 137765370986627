import { useApolloClient, useMutation } from '@apollo/client';
import { RootLoader } from '@fullcontour/common';
import {
  getIgnoredMessageIds,
  removeNonDismissed,
} from '@fullcontour/common/src/components/views/AdminMessages/AdminMessageModal/helpers';
import {
  UPDATE_PASSWORD,
  UPDATE_USER_SIGNIN_TIME,
} from '@fullcontour/shared-api';
import {
  confirmResetPassword,
  confirmSignUp,
  fetchUserAttributes,
  getCurrentUser,
  resendSignUpCode,
  resetPassword,
  signIn,
  signOut,
  signUp,
  updatePassword,
} from 'aws-amplify/auth';
import PropTypes from 'prop-types';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export const AuthContext = createContext(null);

export function Authenticator({ children }) {
  const history = useHistory();
  const [lastLocation, setLastLocation] = useState(null);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [tempCredentials, setTempCredentials] = useState(null);
  const [error, setError] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const client = useApolloClient();
  const [renewPassword] = useMutation(UPDATE_PASSWORD);
  const [isLoading, setIsLoading] = useState(true);
  const lastUrlRef = useRef();
  const app = import.meta.env.VITE_APP_NAME;
  const { pathname } = history.location;

  useEffect(() => {
    if (pathname !== '/new-password' && pathname !== '/legal') {
      (async () => {
        await updateCurrentUser();
      })();
    } else {
      setIsLoading(false);
      setIsAuthChecked(true);
    }
    setLastLocation(history.location.pathname);
    window.addEventListener('storage', handleInvalidSession);
  }, []);

  useEffect(() => {
    if (
      !isSignedIn &&
      !currentUser &&
      !pathname.includes('/legal') &&
      !pathname.includes('/forgot-password') &&
      !pathname.includes('/reset-password') &&
      !pathname.includes('/new-password') &&
      isAuthChecked
    ) {
      history.replace('/signin');
    }
  }, [isSignedIn, history.location, isAuthChecked, isLoading]);

  const handleInvalidSession = (e) => {
    if (e.key === 'isSignedIn' && e.oldValue && !e.newValue) {
      (async () => {
        await logOut();
      })();
    }
    if (e.key === 'isSignedIn' && !e.oldValue && e.newValue) {
      window.location.href = lastUrlRef.current;
    }
  };

  const updateLoginTime = async () => {
    await client.mutate({
      mutation: UPDATE_USER_SIGNIN_TIME,
      variables: {
        input: {
          clientMutationId: uuidv4(),
        },
      },
    });
  };

  const login = async (input) => {
    try {
      const response = await signIn(input);
      setError(null);
      switch (response.nextStep.signInStep) {
        case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
          setTempCredentials({ username: input.username });
          history.push('/new-password');
          break;
        case 'DONE':
          await updateCurrentUser();
          setIsSignedIn(true);
          // history.push('/orders');
          break;
        default:
          break;
      }
      return true;
    } catch (error) {
      setError(error.message);
      return false;
    }
  };

  const logOut = async () => {
    try {
      await signOut();
      setIsSignedIn(false);
      setCurrentUser(null);
      setError(null);
      setLastLocation(null);
      lastUrlRef.current = history.location.pathname;
      history.push('/signin');
      window.localStorage.removeItem('isSignedIn');
      const ignoredMessageIds = getIgnoredMessageIds(currentUser.email);
      if (!ignoredMessageIds.dismissed) {
        removeNonDismissed(currentUser.email);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const register = async (input) => {
    try {
      await signUp(input);
      setTempCredentials({ username: input.username.trim().toLowerCase() });
      setError(null);
      history.push('/confirm-signup');
    } catch (error) {
      setError(error.message);
    }
  };

  const updateCurrentUser = async () => {
    try {
      const response = await fetchUserAttributes();
      const secondResponse = await getCurrentUser();
      const user = {
        username: secondResponse.username,
        locationId: response['custom:locationId'],
        internalId: response['custom:internalId'],
        dismissedHelp:
          response['custom:dismissedHelp'] === '0' ||
          response['custom:dismissedHelp'] === '1'
            ? parseInt(response['custom:dismissedHelp'], 10) === 1
            : true,
        email_verified: response.email_verified === 'true',
        state: response['custom:state'] || '',
        organizationId: response['custom:organizationId'] || '',
        scopeToLocation:
          parseInt(response['custom:scopeToLocation'] || '0', 10) === 1,
        language: response['custom:language'] || 'en',
        roleLevel: parseInt(response['custom:roleLevel'] || '0', 10),
        name: response.name || '',
        family_name: response.family_name || '',
        email: response.email || '',
        sub: response.sub || '',
      };
      setCurrentUser(user);
      setIsSignedIn(true);
      if (!window.localStorage.getItem('isSignedIn')) {
        window.localStorage.setItem('isSignedIn', 'false');
      }
      setError(null);
      await updateLoginTime();
    } catch (error) {
      setError(error.message);
      history.push('/signin');
    } finally {
      setIsLoading(false);
      setIsAuthChecked(true);
    }
  };

  const forgotPassword = async (input) => {
    try {
      await resetPassword(input);
      setTempCredentials({ username: input.username.trim().toLowerCase() });
      setError(null);
      history.push('/reset-password');
    } catch (error) {
      setError(error.message);
    }
  };

  const forgotPasswordSubmit = async (input) => {
    try {
      await confirmResetPassword(input);
      setError(null);
      // history.push('/signin');
    } catch (error) {
      setError(error.message);
      throw new Error(error.message);
    }
  };

  const updateProfilePassword = async (input) => {
    try {
      await updatePassword(input);
      setError(null);
    } catch (error) {
      setError(error.message);
      throw new Error(error.message);
    }
  };

  const confirmRegistration = async (input) => {
    try {
      await confirmSignUp(input);
      setError(null);
      history.push('/signin');
    } catch (error) {
      setError(error.message);
    }
  };

  const changePassword = async (input) => {
    const { password } = input;
    let klass;
    switch (app) {
      case 'customer_portal':
        klass = 'User';
        break;
      case 'designer_portal':
        klass = 'DesignUser';
        break;
      case 'manuf_portal':
        klass = 'ManufacturerUser';
        break;
      default:
        klass = 'AdminUser';
        break;
    }
    try {
      const params = {
        variables: {
          input: {
            clientMutationId: uuidv4(),
            input: {
              password,
              // currentPassword,
              klass,
              email: tempCredentials.username,
            },
          },
        },
      };
      await renewPassword(params);
      setError(null);
      // history.push('/signin');
    } catch (error) {
      setError(error.message);
      throw new Error(error.message);
    }
  };

  const resendSignUpConfirmationCode = async (input) => {
    try {
      await resendSignUpCode(input);
      setError(null);
    } catch (error) {
      setError(error.message);
    }
  };

  const cleanError = () => {
    setError(null);
  };

  const values = useMemo(
    () => ({
      lastLocation,
      tempCredentials,
      error,
      cleanError,
      login,
      logOut,
      signUp: register,
      updateCurrentUser,
      forgotPassword,
      forgotPasswordSubmit,
      confirmSignUp: confirmRegistration,
      changePassword,
      currentUser,
      isSignedIn,
      app,
      updateProfilePassword,
      resendSignUpCode: resendSignUpConfirmationCode,
    }),
    [currentUser, isSignedIn, error, tempCredentials, lastLocation],
  );

  return (
    <AuthContext.Provider value={values}>
      {isLoading || !isAuthChecked ? <RootLoader /> : children}
    </AuthContext.Provider>
  );
}

Authenticator.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAmplify() {
  return useContext(AuthContext);
}
