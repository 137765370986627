import NavMenuLink from '../NavMenuLink/NavMenuLink';

function LegalShowNavMenu() {
  return (
    <>
      <NavMenuLink to="/legal" name="Terms and Conditions" exact />
      <NavMenuLink to="/legal/privacy-policy" name="Privacy Policy" />
      <NavMenuLink to="/legal/baa" name="Business Associate Agreement" />
    </>
  );
}

export default LegalShowNavMenu;
