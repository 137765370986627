import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './SingleDateSelect.css';

function SingleDateSelect({
  onCalendarDateChange,
  date,
  anchorDirection = 'left',
}) {
  const [focus, setFocus] = useState(false);

  return (
    <div className="ordersCalWrap">
      <SingleDatePicker
        onDateChange={(d) => onCalendarDateChange(d)}
        onFocusChange={({ focused }) => setFocus(focused)}
        focused={focus}
        numberOfMonths={1}
        isOutsideRange={(day) => moment().diff(day) < 0}
        date={date}
        showDefaultInputIcon
        anchorDirection={anchorDirection}
      />
    </div>
  );
}

SingleDateSelect.propTypes = {
  date: PropTypes.object.isRequired,
  onCalendarDateChange: PropTypes.func.isRequired,
  anchorDirection: PropTypes.string,
};

export default SingleDateSelect;
