import { GET_BUSINESS_UNITS_DROPDOWN } from '@fullcontour/shared-api';
import { Field, withFormik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import 'react-credit-cards/es/styles-compiled.css';
import { v4 as uuidv4 } from 'uuid';
import {
  BoxIcon,
  FieldContainer,
  SelectField,
  ServerErrors,
  withFormWrap,
} from '../../../../shared';
import { initialValues, schema, withCreate, withUpdate } from './helpers';

function BillingChargeFormModal({
  modalOpen,
  closeModal,
  submitForm,
  destroyDebit,
  refetch,
  billingType,
  resetForm,
  errorMessages,
  errors = {},
  touched = {},
  action = null,
  debit = null,
}) {
  const resetAndCloseModal = () => {
    resetForm();
    closeModal();
  };

  return action ? (
    <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <BoxIcon name="bxs-plus-circle is-size-3" />
          <span>
            <h4 className="title is-5 mb-0 ml-4 modal-card-title">
              {action} {billingType}
            </h4>
          </span>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <div className="control has-icons-left">
              <div
                className={`icon is-left has-text-white ${
                  errors.amount && touched.amount
                    ? 'has-background-danger'
                    : 'has-background-success'
                }`}
                style={{ width: 40, borderRadius: '10% 0 0 10%' }}
              >
                $
              </div>
              <Field
                id="amount"
                name="amount"
                type="number"
                step="0.01"
                placeholder="0.00"
                className="input pl-6"
              />
            </div>

            <div className="control mt-3">
              <FieldContainer name="reason" required>
                <Field
                  id="reason"
                  name="reason"
                  component="textarea"
                  rows="2"
                  required
                  placeholder="Reason"
                  className="textarea"
                />
              </FieldContainer>
            </div>
            <div className="control mt-3">
              <FieldContainer name="Business Unit" required>
                <Field
                  name="businessUnitId"
                  required
                  component={SelectField}
                  query={GET_BUSINESS_UNITS_DROPDOWN}
                  initialMenu
                />
              </FieldContainer>
            </div>
          </div>
          <ServerErrors errorMessages={errorMessages} />
        </section>
        <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
          <button
            className="button is-dark"
            type="button"
            onClick={resetAndCloseModal}
          >
            Close
          </button>
          {action === 'Edit' && (
            <button
              className="button is-danger is-outlined"
              type="button"
              onClick={() => destroyDebit(debit, refetch)}
            >
              Delete
            </button>
          )}
          <button
            className="button is-primary"
            type="button"
            onClick={submitForm}
          >
            {action === 'New' ? 'Create' : 'Update'}
          </button>
        </footer>
      </div>
    </div>
  ) : null;
}

BillingChargeFormModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  action: PropTypes.string,
  errors: PropTypes.object,
  submitForm: PropTypes.func.isRequired,
  destroyDebit: PropTypes.func.isRequired,
  debit: PropTypes.object,
  errorMessages: PropTypes.array.isRequired,
  resetForm: PropTypes.func.isRequired,
  touched: PropTypes.object,
  refetch: PropTypes.func.isRequired,
  billingType: PropTypes.string.isRequired,
};

export default flowRight(
  withFormWrap({ initialValues, schema }),
  withUpdate,
  withCreate,
  withFormik({
    mapPropsToValues: (props) => props.initialValues(props),
    validationSchema: (props) => props.schema(props),
    enableReinitialize: true,
    handleSubmit: (values, { props, setSubmitting }) => {
      const variables = {
        variables: {
          input: { input: values, clientMutationId: uuidv4() },
        },
      };

      if (props.action === 'New') {
        delete variables.variables.input.input.id;
        props.create(variables);
      } else {
        props.update(variables);
      }

      setSubmitting(false);
    },
  }),
)(BillingChargeFormModal);
