import { graphql } from '@apollo/client/react/hoc';
import { UPDATE_PROFILE } from '@fullcontour/shared-api';

const withUpdate = graphql(UPDATE_PROFILE, {
  name: 'update',
  options: (props) => ({
    onCompleted: (data) => {
      const { updateProfile } = data;
      if (updateProfile.errorMessages.length) {
        props.setErrorMessages(updateProfile.errorMessages);
      } else {
        props.clearErrorMessages();
      }
    },
  }),
});

export default withUpdate;
