import PropTypes from 'prop-types';
import { createElement } from 'react';
import { Link } from 'react-router-dom';

function GridCellStyled({ value, data, column }) {
  const { colDef } = column;

  const componentProps = {
    style: { display: 'flex', width: '100%', height: '100%' },
  };
  let component = 'span';
  if (colDef.color) {
    componentProps.style.color = colDef.color;
  }
  if (colDef.link) {
    component = Link;
    const linkPath = colDef.slug.split('/');
    const link = data[linkPath[0]][linkPath[1]];
    const fullLink = `${colDef.link}${link}`;
    componentProps.to = fullLink;
  }
  return createElement(component, componentProps, value);
}

GridCellStyled.propTypes = {
  data: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
};

export default GridCellStyled;
