import { ApolloProvider } from '@apollo/client';
import {
  apolloClientBasic,
  ErrorBoundry,
  history,
  RootLoader,
} from '@fullcontour/common';
import '@fullcontour/common/src/config/i18n/i18next';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import App from './App';
import './index.scss';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ApolloProvider client={apolloClientBasic}>
    <Suspense fallback={<RootLoader loading />}>
      <ErrorBoundry>
        <Router history={history}>
          <App />
        </Router>
      </ErrorBoundry>
    </Suspense>
  </ApolloProvider>,
);

serviceWorker.unregister();
