import { AuthContext } from '@fullcontour/auth';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

function BreadCrumbPathHome() {
  const { t } = useTranslation('navigation');
  const { isSignedIn } = useContext(AuthContext);
  return (
    <li>
      <NavLink to={isSignedIn ? '/' : '/signin'} exact>
        {t('Home')}
      </NavLink>
    </li>
  );
}

export default BreadCrumbPathHome;
