import { BaseLoader, LanguageProvider } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { lazy, Suspense, useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

const AsyncLegal = lazy(() => import('./views/Legal'));
const AsyncHome = lazy(() => import('./views/Home'));
const AsyncApprovalFull = lazy(() => import('./views/ApprovalFull'));
const AsyncApprovalLite = lazy(() => import('./views/ApprovalLite'));

function App(props) {
  const { app } = props;
  const { i18n } = useTranslation();

  const [locale, setLocale] = useState(
    window.localStorage.getItem('defaultLanguage') || 'en',
  );

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [i18n, locale]);

  function changeDefaultLanguage(value) {
    setLocale(value);
  }

  const checkServiceWorkerUpdate = () => {
    if (
      import.meta.env.NODE_ENV === 'production' &&
      'serviceWorker' in navigator
    ) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.update();
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(checkServiceWorkerUpdate, 900000);
    return () => clearInterval(interval);
  }, []);

  return (
    <LanguageProvider value={{ locale, changeDefaultLanguage }}>
      <Suspense fallback={<BaseLoader />}>
        <main
          style={{
            marginTop: 0,
          }}
        >
          <CookiesProvider>
            <Switch>
              <Route exact path="/" render={() => <AsyncHome />} />
              {app === 'full' ? (
                <Route
                  path="/approval/:id"
                  render={() => <AsyncApprovalFull app={app} />}
                />
              ) : (
                <Route
                  path="/approval/:id"
                  render={() => <AsyncApprovalLite app={app} />}
                />
              )}
              <Route exact path="/legal" render={() => <AsyncLegal />} />
            </Switch>
          </CookiesProvider>
        </main>
      </Suspense>
    </LanguageProvider>
  );
}

App.propTypes = {
  app: PropTypes.string.isRequired,
};

export default App;
