import { ApprovalFooter } from '@fullcontour/approval-shared';
import { useEffect } from 'react';
import Main from './components/routing/Main';

function App() {
  const checkServiceWorkerUpdate = () => {
    if (
      import.meta.env.NODE_ENV === 'production' &&
      'serviceWorker' in navigator
    ) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.update();
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(checkServiceWorkerUpdate, 900000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <Main />
      <ApprovalFooter style={{ marginTop: 0 }} />
    </div>
  );
}

export default App;
