import { SignInLanguageSelect } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function AuthLayout({ children, app, newPassword = false }) {
  const { t } = useTranslation('signin');
  let appPrefix;

  if (app === 'admin_portal') {
    appPrefix = 'Admin';
  }

  if (app === 'designer_portal') {
    appPrefix = 'Designer';
  }

  if (app === 'manuf_portal') {
    appPrefix = 'Manufacturer';
  }
  const signInText = appPrefix ? `${appPrefix} Sign In` : 'Sign In';
  return (
    <div
      style={{ minHeight: '100vh', background: '#F6F6F6' }}
      className="columns"
    >
      <div className="column is-5 has-background-white is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
        <div style={{ maxWidth: 600 }} className="has-text-centered">
          <div
            className="my-4"
            style={{
              maxWidth: 500,
              margin: '0 auto',
              overflow: 'hidden',
              borderRadius: 20,
            }}
          >
            <img
              src="https://cdn.fullcontour.com/logos/3ShapeDesignServiceLogoGrey.svg"
              alt="3Shape design action service logo"
            />
          </div>
        </div>
        <div style={{ maxWidth: 600 }} className="has-text-centered">
          <h2 className="title is-2 has-text-centered">
            {newPassword ? 'Reset your password' : t(signInText)}
          </h2>
          {children}
        </div>
      </div>
      <div className="column is-7 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
        <div style={{ maxWidth: 750 }}>
          <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center mb-3">
            <img
              src="https://cdn.fullcontour.com/logos/gis-login-transparent-nologo.png"
              alt="Guided Implant Surgery"
              style={{
                maxWidth: 600,
                margin: '0 auto',
              }}
            />
            <div className="has-text-centered subtitle is-5 mb-4 mt-2">
              <p>
                <span style={{ color: '#d1004b' }}>Webinar Replay</span> -
                Maximizing Surgical Guides for your Business
              </p>
              <a
                href="https://l.3shape.com/ug1qY"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="button is-primary mt-4" type="button">
                  Watch Now
                </button>
              </a>
            </div>
            <div className="has-text-centered subtitle is-6 mb-2 mt-2">
              <p>
                <span>Have questions? Email us: </span>
              </p>
              <a href="mailto:designservices@3shape.com">
                designservices@3shape.com
              </a>
              <p>Or</p>
              <p>
                <a
                  href="https://3ShapeDesignServices.as.me/LabDesignServicesDemo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Book a free demo
                </a>
              </p>
            </div>
          </div>
        </div>
        <SignInLanguageSelect authState="signIn" />
      </div>
    </div>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  app: PropTypes.string.isRequired,
  newPassword: PropTypes.bool,
};

export default AuthLayout;
