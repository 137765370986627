/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LoadingOrError } from '../../Loader';
import { FieldContainer } from '../FieldContainer';

function MultiSelectById({
  name,
  form: { values },
  query,
  dataName,
  push,
  remove,
  label = null,
  cellLabel = '',
  variables = {},
  nestedDataName = null,
}) {
  const { t } = useTranslation('formfields');

  function handleChange(foundindex, mappedobjectid) {
    if (foundindex === -1) {
      push(mappedobjectid);
      return;
    }

    if (foundindex !== -1) {
      remove(foundindex);
    }
  }

  const { error, loading, data } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  if (loading || error)
    return <LoadingOrError error={error} loading={loading} title={cellLabel} />;

  let loadedData = data[dataName];

  if (nestedDataName) {
    loadedData = loadedData[nestedDataName];
  }

  return (
    <FieldContainer required name={name} label={label}>
      <table className="table is-bordered is-narrow is-hoverable is-fullwidth">
        <thead className="has-background-white-ter">
          <tr>
            <th className="py-4 has-background-white" />
            <th className="py-4">{t(cellLabel)}</th>
          </tr>
        </thead>
        <tbody style={{ lineHeight: '0.75em' }}>
          {loadedData.map((obj) => (
            <tr key={obj.id}>
              <td
                style={{ width: 30, verticalAlign: 'middle' }}
                className="py-0 has-background-white-ter"
              >
                <div
                  className="field"
                  style={{ position: 'relative', top: '0.6em' }}
                >
                  <input
                    id={obj.id}
                    name={obj.id}
                    type="checkbox"
                    className="switch is-rounded is-thin"
                    checked={values[name].some((type) => type === obj.id)}
                    onChange={() =>
                      handleChange(
                        values[name].findIndex((type) => type === obj.id),
                        obj.id,
                      )
                    }
                  />
                  <label htmlFor={obj.id} />
                </div>
              </td>
              <td
                className="py-0 is-size-6"
                style={{ verticalAlign: 'middle' }}
              >
                {obj.name || obj.text}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </FieldContainer>
  );
}

MultiSelectById.propTypes = {
  name: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  }).isRequired,
  query: PropTypes.object.isRequired,
  variables: PropTypes.object,
  dataName: PropTypes.string.isRequired,
  nestedDataName: PropTypes.string,
  label: PropTypes.string,
  cellLabel: PropTypes.string,
};

export default MultiSelectById;
