function initialValues(props) {
  const { customer, type, credit = {} } = props;

  let orgId;
  let locId;

  if (type === 'location') {
    locId = customer.id;
    orgId = customer.organization.id;
  }

  if (type === 'organization') {
    locId = '';
    orgId = customer.id;
  }

  return {
    id: credit.id || null,
    amount: credit.amount || '',
    organizationId: credit.organizationId || orgId,
    locationId: credit.locationId || locId,
    reason: credit.reason || '',
  };
}

export default initialValues;
