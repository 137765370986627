/* eslint-disable no-prototype-builtins */
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router';
import FormFieldArrayInner from './FormFieldArrayInner';
import FormFieldArrayModal from './FormFieldArrayModal';

function FormFieldArray({
  fieldsConfig = {},
  components,
  formTitle,
  sections,
  actions = {},
  dataSources = {},
  validationSchema,
  formReviewModal: { render, showModalOnSubmit, modalTitle, buttontext } = {},
  showNextOnComplete = false,
  stepperExceptions = [],
  initialValues,
  linkNames,
}) {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [showAllErrors, setShowAllErrors] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState({
    _sectionIndex: 0,
    _fieldIndex: 0,
  });
  const [currentIndex, setCurrentIndex] = useState(0);

  const [create] = useMutation(actions?.create?.query || '', {});

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      validateOnMount
      validateOnBlur={false}
      onSubmit={async (values) => {
        if (
          !values.hasOwnProperty('id') ||
          (values.hasOwnProperty('id') &&
            (values.id === '' || values.id === null || values.id === 0))
        ) {
          // #region Temporary Code
          const orderItems = values.orderItems.map(
            ({ printerId, ...rest }) => ({
              ...rest,
            }),
          );
          await create({
            variables: {
              input: {
                input: {
                  name: values.name,
                  instructions: values.instructions,
                  files: values.files.filter(
                    (item) => typeof item === 'object',
                  ),
                  orderItems,
                  turnaroundTimeId: values.turnaroundTimeId,
                  requiresDesignApproval: values.requiresDesignApproval,
                  intOrderId: values.intOrderId,
                  software: '',
                },
              },
            },
          });
          history.push('/');
          // #endregion
        } else {
          // await update({ variables: { input: { input: { ...values } } } });
        }
      }}
    >
      {({ handleSubmit }) => (
        <>
          <form onSubmit={handleSubmit} style={{ minWidth: '100%' }}>
            <FormFieldArrayInner
              formTitle={formTitle}
              dataSources={dataSources}
              currentIndex={currentIndex}
              showAllErrors={showAllErrors}
              fieldsConfig={fieldsConfig}
              currentStepIndex={currentStepIndex}
              setCurrentStepIndex={setCurrentStepIndex}
              setCurrentIndex={setCurrentIndex}
              showNextOnComplete={showNextOnComplete}
              setShowAllErrors={setShowAllErrors}
              components={components}
              stepperExceptions={stepperExceptions}
              actions={actions}
              showModalOnSubmit={showModalOnSubmit}
              setShowModal={setShowModal}
              sections={sections}
              linkedNames={linkNames}
            />
          </form>
          <FormFieldArrayModal
            showModal={showModal}
            setShowModal={setShowModal}
            modalTitle={modalTitle}
            buttontext={buttontext}
            render={render}
          />
        </>
      )}
    </Formik>
  );
}

FormFieldArray.propTypes = {
  initialValues: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  fieldsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      sectionName: PropTypes.string.isRequired,
      labelText: PropTypes.string,
      placeholder: PropTypes.string,
      componentName: PropTypes.string.isRequired,
      initialValue: PropTypes.any.isRequired,
      componentProps: PropTypes.object,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          sectionName: PropTypes.string.isRequired,
          labelText: PropTypes.string.isRequired,
          placeholder: PropTypes.string,
          componentName: PropTypes.string.isRequired,
          fieldValidation: PropTypes.object.isRequired,
          initialValue: PropTypes.any.isRequired,
          fieldIndex: PropTypes.number.isRequired,
          componentProps: PropTypes.object,
        }).isRequired,
      ),
    }),
  ),
  sections: PropTypes.object.isRequired,
  dataSources: PropTypes.object,
  formTitle: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    create: PropTypes.shape({
      submitButtonText: PropTypes.string.isRequired,
      query: PropTypes.object.isRequired,
    }),
    update: PropTypes.shape({
      submitButtonText: PropTypes.string.isRequired,
      query: PropTypes.object.isRequired,
    }),
    destroy: PropTypes.shape({
      submitButtonText: PropTypes.string.isRequired,
      query: PropTypes.object.isRequired,
    }),
  }),
  formReviewModal: PropTypes.shape({
    render: PropTypes.func.isRequired,
    showModalOnSubmit: PropTypes.bool.isRequired,
    modalTitle: PropTypes.string,
    buttontext: PropTypes.string,
  }),
  showNextOnComplete: PropTypes.bool,
  stepperExceptions: PropTypes.arrayOf(PropTypes.string),
  linkNames: PropTypes.object.isRequired,
};

export default FormFieldArray;
