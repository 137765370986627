import { lazy } from 'react';

const OrderShowNavMenu = lazy(
  () => import('../../shared/NavMenus/Orders/OrderShow/OrderShowNavMenu'),
);

const routes = [
  {
    path: [
      '/:root(orders)/:type(details)/:id',
      '/:root(orders)/:type(details)/:id/:action(activity|messages|order-info|design-preferences)',
    ],
    component: OrderShowNavMenu,
  },
];

export default routes;
