import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function ApprovalFooter({ style = {}, approvalTerms = true }) {
  return (
    <footer
      style={{
        width: '100%',
        marginTop: 100,
        backgroundColor: '#e0e0e0',
        ...style,
      }}
    >
      <div className="container is-flex is-justify-content-flex-end">
        <Link
          to="/"
          style={{
            padding: '1em 0',
            display: 'inline-block',
            marginRight: '1em',
          }}
        >
          About
        </Link>
        {approvalTerms && (
          <Link
            to="/legal"
            style={{
              padding: '1em 0',
              display: 'inline-block',
              marginRight: '1em',
            }}
          >
            Legal
          </Link>
        )}
      </div>
    </footer>
  );
}

ApprovalFooter.propTypes = {
  style: PropTypes.object,
  approvalTerms: PropTypes.bool,
};

export default ApprovalFooter;
