import { useQuery } from '@apollo/client';
import { GET_DESIGN_LOCS } from '@fullcontour/shared-api';
import PropTypes from 'prop-types';
import { AgGridContainer } from '../../../../shared';

function ReassignModalDesignLocs(props) {
  const { onRowClicked } = props;

  const { error, loading, data } = useQuery(GET_DESIGN_LOCS, {
    fetchPolicy: 'cache-and-network',
  });

  if (error) return <p>Error...</p>;
  if (loading) return <p>Loading</p>;

  const { designLocations } = data;
  const gridOptions = {
    rowData: designLocations,
    rowSelection: 'single',
    rowClass: 'reassignSiloRow',
    columnDefs: [
      {
        field: 'name',
        headerName: 'Silo',
        minWidth: 150,
        sort: 'asc',
        filter: 'agTextColumnFilter',
        editable: false,
        suppressMenu: true,
        resizable: true,
      },
      {
        field: 'organization.name',
        headerName: 'Design Center',
        minWidth: 200,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
      },
    ],
  };

  return (
    <AgGridContainer
      gridOptions={gridOptions}
      containerHeight={300}
      onRowClicked={onRowClicked}
    />
  );
}

ReassignModalDesignLocs.propTypes = {
  onRowClicked: PropTypes.func.isRequired,
};

export default ReassignModalDesignLocs;
