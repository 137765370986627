import {
  Message,
  SignInAppSelect,
  SignInLegal,
  SignInSignUpButton,
} from '@fullcontour/common';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';

function SignIn({
  login,
  app,
  cleanError,
  currentUser = null,
  error = null,
  lastLocation = null,
}) {
  const [revealPassword, setRevealPassword] = useState(false);
  const { t } = useTranslation('signin');
  const { handleSubmit, handleChange, values, errors, isSubmitting } =
    useFormik({
      initialValues: {
        username: '',
        password: '',
      },
      onSubmit: async (values) => {
        await login({
          username: values.username.trim().toLowerCase(),
          password: values.password.trim(),
        });
      },
      validate: (values) => {
        const errors = {};
        if (values.username === '') {
          errors.username = 'Please enter your email or username. Required';
        }
        if (values.password.trim().length < 8) {
          errors.password = 'Please enter your password. Required';
        }
        return errors;
      },
    });

  useEffect(
    () => () => {
      cleanError();
    },
    [],
  );

  return !currentUser ? (
    <>
      {error &&
        error !== 'User needs to be authenticated to call this API.' && (
          <Translation ns={['signin']}>
            {(t) => (
              <Message
                type="danger"
                title="Sign In"
                dismissable
                error={error}
                onDismiss={cleanError}
              >
                <p>{t(error)}</p>
              </Message>
            )}
          </Translation>
        )}
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label className="label" htmlFor="username">
            {t('Email or Username')}
          </label>
          <p className="control has-icons-left has-icons-right">
            <input
              className="input"
              type="text"
              onChange={handleChange}
              value={values.username}
              placeholder={t('Enter your email or username')}
              name="username"
            />
            <span className="icon is-small is-left">
              <i className="bx bxs-envelope" />
            </span>
            <span className="icon is-small is-right">
              {errors.username ? (
                <i className="bx bxs-error" />
              ) : (
                <i className="bx bx-check" />
              )}
            </span>
          </p>
          <p className="help is-danger">{errors.username}</p>
        </div>
        <div className="field">
          <label className="label" htmlFor="password">
            {t('Password')}
          </label>
          <p className="control has-icons-left has-icons-right">
            <input
              className="input"
              type={revealPassword ? 'text' : 'password'}
              onChange={handleChange}
              value={values.password}
              placeholder={t('Password')}
              name="password"
            />
            <span className="icon is-small is-left">
              <i className="bx bxs-lock" />
            </span>
            <span className="icon is-small is-right is-clickable">
              {revealPassword ? (
                <i
                  className="bx bxs-show"
                  onClick={() => setRevealPassword(!revealPassword)}
                  onKeyDown={null}
                />
              ) : (
                <i
                  className="bx bxs-hide"
                  onClick={() => setRevealPassword(!revealPassword)}
                  onKeyDown={null}
                />
              )}
            </span>
          </p>
          <p className="help is-danger">{errors.password}</p>
        </div>
        <div className="field is-grouped is-grouped-centered my-5">
          {app !== 'designer_portal' ? (
            <>
              <div className="control">
                <span className="title is-6 ">
                  {t('Forget your password?')}
                </span>
              </div>
              <div className="control">
                <Link to="/forgot-password">
                  <small>{t('Reset password')}</small>
                </Link>
              </div>
            </>
          ) : (
            <div className="control">
              <span className="title is-6">
                {t('Forget your password? Please contact support.')}
              </span>
            </div>
          )}
        </div>
        <div className="field">
          <div className="control">
            <button
              type="submit"
              className={`button is-primary input ${
                isSubmitting ? 'is-loading' : ''
              }`}
            >
              {t('Sign In')}
            </button>
          </div>
        </div>
        <SignInSignUpButton app={app} />
        <SignInAppSelect app={app} />
        <SignInLegal authState="signIn" />
      </form>
    </>
  ) : (
    <Redirect to={lastLocation ?? '/orders'} />
  );
}

SignIn.propTypes = {
  login: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  app: PropTypes.string.isRequired,
  error: PropTypes.string,
  cleanError: PropTypes.func.isRequired,
  lastLocation: PropTypes.string,
};

export default SignIn;
