import { graphql } from '@apollo/client/react/hoc';
import { UPDATE_DEBIT } from '@fullcontour/shared-api';

const withUpdate = graphql(UPDATE_DEBIT, {
  name: 'update',
  options: (props) => ({
    onCompleted: (data) => {
      const { updateDebit } = data;
      if (updateDebit.errorMessages.length) {
        props.setErrorMessages(updateDebit.errorMessages);
      } else {
        props.clearErrorMessages();
        props.refetch();
        props.closeModal();
      }
    },
  }),
});

export default withUpdate;
