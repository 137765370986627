function initialValues(props) {
  const { selectedItem = {} } = props;

  return {
    invoiceId: selectedItem.id,
    isCard: false,
    message: '',
    cardBilled: '',
    paymentAmount: 0.0,
  };
}

export default initialValues;
