import { graphql } from '@apollo/client/react/hoc';
import { UPDATE_NOTIFICATION_TYPE_USER } from '@fullcontour/shared-api';

const withUpdate = graphql(UPDATE_NOTIFICATION_TYPE_USER, {
  name: 'update',
  options: (props) => ({
    errorPolicy: 'all',
    onCompleted: (data) => {
      const { updateNotificationTypeUser } = data;
      if (updateNotificationTypeUser.errorMessages.length) {
        props.setErrorMessages(updateNotificationTypeUser.errorMessages);
      } else {
        props.clearErrorMessages();
      }
    },
    onError: (err) => {
      props.setErrorMessages([err]);
    },
  }),
});

export default withUpdate;
