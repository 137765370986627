import PropTypes from 'prop-types';
import { AgGridContainer } from '../../../../../shared';

function ResultsAgingCustomer({ reportDetails }) {
  const gridOptions = {
    rowData: reportDetails,
    defaultColDef: {
      floatingFilter: true,
    },
    suppressAggFuncInHeader: true,
    groupIncludeTotalFooter: true,
    columnDefs: [
      {
        field: 'name',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        sort: 'asc',
        cellStyle: { color: '#1B77C8', cursor: 'pointer' },
        onCellClicked: (params) => {
          params.context.history.push(
            `/locations/customer/${params.data.locationSlug}`,
          );
        },
      },
      {
        field: 'tags',
        headerName: 'Tags',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'paymentTerm',
        minWidth: 150,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'range0',
        headerName: '0-15 Days',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        valueFormatter: (p) => `$${p.value ? p.value.toFixed(2) : 0}`,
        aggFunc: 'sum',
      },
      {
        field: 'range1',
        headerName: '16-30 Days',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        valueFormatter: (p) => `$${p.value ? p.value.toFixed(2) : 0}`,
        aggFunc: 'sum',
      },
      {
        field: 'range2',
        headerName: '31-45 Days',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        valueFormatter: (p) => `$${p.value ? p.value.toFixed(2) : 0}`,
        aggFunc: 'sum',
      },
      {
        field: 'range3',
        headerName: '46-90 Days',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        valueFormatter: (p) => `$${p.value ? p.value.toFixed(2) : 0}`,
        aggFunc: 'sum',
      },
      {
        field: 'range4',
        headerName: '90+ Days',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        valueFormatter: (p) => `$${p.value ? p.value.toFixed(2) : 0}`,
        aggFunc: 'sum',
      },
      {
        field: 'totals',
        headerName: 'Totals',
        type: 'numericColumn',
        minWidth: 55,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        valueFormatter: (p) => `$${p.value ? p.value.toFixed(2) : 0}`,
        aggFunc: 'sum',
      },
    ],
  };

  return <AgGridContainer gridOptions={gridOptions} />;
}

ResultsAgingCustomer.propTypes = {
  reportDetails: PropTypes.array.isRequired,
};

export default ResultsAgingCustomer;
