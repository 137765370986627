/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as THREE from 'three';
import { MovementTableWithViewerContext } from '../../shared/Context/MovementTableWithViewerContext';
import { OrthoProvider } from '../../shared/Context/OrthoContext';
import AdditionalControls from './Controls/AdditionalControls/AdditionalControls';
import BeforeAfterControl from './Controls/BeforeAfterControl/BeforeAfterControl';
import PerspectiveControl from './Controls/PerspectiveControl/PerspectiveControl';
import Slider from './Controls/Slider/Slider';
import Styles from './OrthoViewer.module.scss';
import ThreeViewer from './ThreeViewer/ThreeViewer';

function OrthoViewer({
  iprs,
  newOtpVersion,
  theme = 'dark',
  files = [],
  diffFiles = [],
  otpData = null,
  containerStyle = {},
}) {
  const { setActiveStage, activeStage } = useContext(
    MovementTableWithViewerContext,
  );

  const orbit = useRef();
  const group = useRef();
  const objRefs = useRef([]);
  const objRefsOverlay = useRef([]);
  const cameraRef = useRef();

  const rotationYBasedOnOtp = newOtpVersion ? Math.PI : 0;

  const [currentPerspective, setCurrentPerspective] = useState(null);
  const [rotation, setRotation] = useState([0, 0, 0]);
  const [gingivaText, setGingivaText] = useState();
  const [currentStage, setCurrentStage] = useState(0);
  const [parsedDiffs, setParsedDiffs] = useState({});
  const [playing, setPlaying] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(theme);
  const [showAttachmentsButton, setShowAttachmentsButton] = useState(false);
  const [visibility, setVisibility] = useState({
    upper: true,
    lower: true,
    attachments: true,
    ipr: true,
    grid: false,
    gingiva: true,
    overlay: false,
  });

  const currentOtp = useMemo(() => {
    let cOtp;

    if (otpData) {
      cOtp = otpData.find(
        (otp) => parseInt(otp.Number, 10) === parseInt(currentStage, 10),
      );
    }

    return cOtp;
  }, [currentStage, otpData]);

  const previousOtp = useMemo(() => {
    let pOtp;

    if (otpData) {
      pOtp = otpData.find(
        (otp) => parseInt(otp.Number, 10) === parseInt(currentStage - 1, 10),
      );
    }

    return pOtp;
  }, [currentStage, otpData]);

  const stageZeroOtp = useMemo(() => {
    let cOtp;

    if (otpData) {
      cOtp = otpData.find((otp) => parseInt(otp.Number, 10) === 0);
    }

    return cOtp;
  }, [otpData]);

  const toothMaterial = useMemo(
    () =>
      new THREE.MeshPhysicalMaterial({
        color: 0xebeae9,
        reflectivity: 0,
        clearcoatRoughness: 0.17,
        sheen: 0.4,
        clearcoat: 0.4,
        opacity: visibility.overlay ? 0.85 : 1,
        transparent: true,
        ior: 1.54,
      }),
    [visibility.overlay],
  );

  const toothMaterialOverlay = useMemo(
    () =>
      new THREE.MeshPhysicalMaterial({
        color: 0x7777ff,
        reflectivity: 0,
        clearcoatRoughness: 0,
        sheen: 0,
        clearcoat: 0,
        opacity: 0.85,
        wireframe: true,
        transparent: true,
      }),
    [],
  );

  const attachmentMaterial = useMemo(
    () =>
      new THREE.MeshPhysicalMaterial({
        color: 0x909090,
        reflectivity: 0.9,
        clearcoatRoughness: 0.2,
        clearcoat: 0.1,
        sheen: 0.4,
        metalness: 1,
        wireframe: false,
        opacity: 1,
        transparent: false,
      }),
    [],
  );

  useEffect(() => {
    const fetchDiffs = async () => {
      await Promise.all(
        diffFiles.map(async (file) => {
          const { data } = await axios.get(file.signedUrl, {
            headers: {
              Accept: 'binary/octet-stream',
            },
          });

          setParsedDiffs((p) => ({
            ...p,
            [file.originalFileName.split('.json')[0]]: data.VertexModifications,
          }));
        }),
      );
    };

    fetchDiffs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchGingivas = async () => {
      await Promise.all(
        files
          .filter((file) =>
            file.originalFileName.toLowerCase().includes('gingiva'),
          )
          .map(async (file) => {
            const { data } = await axios.get(file.signedUrl, {
              headers: {
                Accept: 'binary/octet-stream',
              },
            });

            setGingivaText((p) => ({
              ...p,
              [file.originalFileName.split('.obj')[0]]: data,
            }));
          }),
      );
    };

    fetchGingivas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePerspective = useCallback(
    (perspective) => {
      const rotate = (90 * Math.PI) / 180;
      let x = 0;
      let y = rotationYBasedOnOtp;
      let upper = true;
      let lower = true;

      if (!group?.current || !orbit?.current) {
        return;
      }

      if (perspective === 'left') {
        y = newOtpVersion ? rotate : -1 * rotate;
      }

      if (perspective === 'right') {
        y = newOtpVersion ? rotate * -1 : rotate;
      }

      if (perspective === 'top') {
        x = -1 * rotate;
        lower = false;
      }

      if (perspective === 'down') {
        x = rotate;
        upper = false;
      }

      setRotation([x, y, 0]);
      setVisibility({ ...visibility, upper, lower });
      setCurrentPerspective(perspective);

      orbit.current.reset();
    },
    [visibility],
  );

  function updateStage(stage) {
    setCurrentStage(stage);

    if (setActiveStage) {
      setActiveStage(stage + 1);
    }
  }

  useEffect(() => {
    if (currentStage !== activeStage - 1) {
      setCurrentStage(activeStage - 1 < 0 ? 0 : activeStage - 1);
    }
  }, [activeStage]);

  return (
    <OrthoProvider
      value={{
        toothMaterial,
        toothMaterialOverlay,
        attachmentMaterial,
        parsedDiffs,
        otpData,
        currentStage,
        gingivaText,
        currentOtp,
        previousOtp,
        stageZeroOtp,
        visibility,
        setVisibility,
        objRefs,
        objRefsOverlay,
        currentPerspective,
        changePerspective,
        group,
        rotation,
        files,
        iprs,
        setShowAttachmentsButton,
        showAttachmentsButton,
        newOtpVersion,
      }}
    >
      <div
        id="canvas-container"
        className={`${Styles.mainCanvas} ${Styles[currentTheme]}`}
        style={containerStyle}
      >
        <ThreeViewer orbit={orbit} cameraRef={cameraRef} />
        <PerspectiveControl />
        <Slider
          currentStage={currentStage}
          totalStages={otpData?.length || 0}
          autoplay={playing}
          changeSlider={updateStage}
        />

        <BeforeAfterControl
          updateStage={updateStage}
          totalStages={otpData?.length || 0}
          currentStage={currentStage}
          playing={playing}
          setPlaying={setPlaying}
          isDarkTheme={currentTheme === 'dark'}
        />
        <AdditionalControls
          setCurrentTheme={setCurrentTheme}
          theme={currentTheme}
        />
      </div>
    </OrthoProvider>
  );
}

OrthoViewer.propTypes = {
  theme: PropTypes.string,
  files: PropTypes.array,
  diffFiles: PropTypes.array,
  otpData: PropTypes.array,
  containerStyle: PropTypes.object,
  iprs: PropTypes.object.isRequired,
  newOtpVersion: PropTypes.bool.isRequired,
};

export default OrthoViewer;
