import PropTypes from 'prop-types';

function GridCellTags({ value }) {
  if (!value?.length) {
    return null;
  }

  return (
    <div className="is-flex is-justify-content-center">
      {value.map((item) => (
        <div key={item} className="tag is-danger">
          {item}
        </div>
      ))}
    </div>
  );
}

GridCellTags.propTypes = {
  value: PropTypes.string.isRequired,
};

export default GridCellTags;
