import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

function GridActionsRow({
  mutliColumn,
  actionAlign = 'has-text-right',
  rootVal = null,
  typeVal = null,
  to = null,
  content = null,
  children = null,
}) {
  const { root, type } = useParams();
  const { t } = useTranslation('navigation');

  return (!rootVal && !typeVal) ||
    (rootVal && !typeVal && rootVal === root) ||
    (rootVal && typeVal && rootVal === root && typeVal === type) ? (
    <div className="gridActionsRow columns">
      {mutliColumn ? (
        { children }
      ) : (
        <div className={`column ${actionAlign}`}>
          {children}
          {to && content && (
            <Link
              to={to}
              style={{ textTransform: 'capitalize' }}
              className="button is-pulled-right is-primary"
            >
              <span className="icon is-small">
                <i className="bx bx-plus-circle" />
              </span>
              <span> {t(content)}</span>
            </Link>
          )}
        </div>
      )}
    </div>
  ) : null;
}

GridActionsRow.propTypes = {
  children: PropTypes.node,
  mutliColumn: PropTypes.bool.isRequired,
  rootVal: PropTypes.string,
  typeVal: PropTypes.string,
  actionAlign: PropTypes.string,
  to: PropTypes.string,
  content: PropTypes.string,
};

export default GridActionsRow;
