import { withFormik } from 'formik';
import { useState } from 'react';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import { withFormWrap } from '../../../../shared';
import { initialValues, schema } from './helpers';

import LocationGuideGroupsHeader from './LocationGuideGroupsHeader/LocationGuideGroupsHeader';
import LocationGuideGroupsBody from './LocationGuideGroupBody/LocationGuideGroupsBody';

function LocationGudieGroupsInner({ setModal, data }) {
  const [editMode, setEditMode] = useState(false);
  const [searchValue, setSearchValue] = useState(null);

  return (
    <>
      <LocationGuideGroupsHeader
        setModal={setModal}
        setEditMode={setEditMode}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        editMode={editMode}
        data={data}
      />
      <LocationGuideGroupsBody
        searchValue={searchValue}
        groupData={data}
        editMode={editMode}
      />
    </>
  );
}

LocationGudieGroupsInner.propTypes = {
  setModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default flowRight(
  withFormWrap({ initialValues, schema }),
  withFormik({
    validationSchema: (props) => props.schema(props),
    mapPropsToValues: (props) => props.initialValues(props),
    enableReinitialize: true,
  }),
)(LocationGudieGroupsInner);
