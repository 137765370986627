import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

function NavMenuLink({ to, name, exact = false }) {
  const { t } = useTranslation('navigation');

  return (
    <li>
      <NavLink
        to={to}
        activeClassName="is-active"
        style={{
          textTransform: 'capitalize',
          borderBottom: '1px solid #ddd',
          fontSize: '13px',
          fontWeight: 500,
          padding: '1em 0.75em',
        }}
        exact={exact}
      >
        {t(name)}
      </NavLink>
    </li>
  );
}

NavMenuLink.propTypes = {
  to: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  exact: PropTypes.bool,
};

export default NavMenuLink;
