import { Field, withFormik } from 'formik';
import { flowRight } from 'lodash-es';
import PropTypes from 'prop-types';
import 'react-credit-cards/es/styles-compiled.css';
import { v4 as uuidv4 } from 'uuid';
import { FieldContainer, ServerErrors, withFormWrap } from '../../../../shared';
import CreditsDropdown from './CreditsDropdown';
import { initialValues, schema, withCreate } from './helpers';

function BillingInvoiceAttachCreditFormModal({
  modalOpen,
  closeModal,
  errors = {},
  touched = {},
  submitForm,
  resetForm,
  customer,
  type,
  maxAmount,
  setMaxAmount,
  errorMessages,
}) {
  const resetAndCloseModal = () => {
    resetForm();
    closeModal();
  };

  return (
    <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <h4 className="title is-5 mb-0 ml-4 modal-card-title">
            Attach Credit
          </h4>
        </header>
        <section className="modal-card-body">
          <Field
            name="creditId"
            required
            component={CreditsDropdown}
            customer={customer}
            type={type}
            setMaxAmount={setMaxAmount}
          />
          <FieldContainer name="amountUsed" label="Amount To Apply" required>
            <div className="control has-icons-left">
              <div
                className={`icon is-left has-text-white ${
                  errors.amount && touched.amount
                    ? 'has-background-danger'
                    : 'has-background-success'
                }`}
                style={{ width: 40, borderRadius: '10% 0 0 10%' }}
              >
                $
              </div>
              <Field
                id="amountUsed"
                name="amountUsed"
                type="number"
                step="0.01"
                placeholder="0.00"
                className="input pl-6"
                max={maxAmount}
              />
            </div>
          </FieldContainer>
          <ServerErrors errorMessages={errorMessages} />
        </section>
        <footer className="modal-card-foot is-flex is-flex-direction-row is-justify-content-flex-end">
          <button
            className="button is-dark"
            type="button"
            onClick={resetAndCloseModal}
          >
            Close
          </button>
          <button
            className="button is-primary"
            type="button"
            onClick={submitForm}
          >
            Attach
          </button>
        </footer>
      </div>
    </div>
  );
}

BillingInvoiceAttachCreditFormModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  submitForm: PropTypes.func.isRequired,
  errorMessages: PropTypes.array.isRequired,
  resetForm: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  maxAmount: PropTypes.number.isRequired,
  setMaxAmount: PropTypes.func.isRequired,
};

export default flowRight(
  withFormWrap({ initialValues, schema }),
  withCreate,
  withFormik({
    mapPropsToValues: (props) => props.initialValues(props),
    validationSchema: (props) => props.schema(props),
    enableReinitialize: true,
    handleSubmit: (values, { props, setSubmitting }) => {
      const variables = {
        variables: { input: { input: values, clientMutationId: uuidv4() } },
      };

      props.create(variables);

      setSubmitting(false);
    },
  }),
)(BillingInvoiceAttachCreditFormModal);
