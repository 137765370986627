import { Suspense, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { BaseLoader } from '../shared';
import { CurrentUserContext, RouteContext } from '../shared/Context';
import sharedRoutes from './main';

function SidebarMainRoutes() {
  const { mainRoutes } = useContext(RouteContext);
  const { currentUser } = useContext(CurrentUserContext);

  const scopedRoutes = mainRoutes.filter((route) => {
    if (currentUser?.scopeToLocation) {
      return route.sl === true;
    }

    return route.so === true;
  });
  return (
    <Suspense fallback={<BaseLoader />}>
      <Switch>
        {scopedRoutes.map((route) => (
          <Route key={route.path} exact path={route.path}>
            <route.component currentUser={currentUser} />
          </Route>
        ))}

        {sharedRoutes.map((route) => (
          <Route key={route.path} exact={route.exact} path={route.path}>
            <route.component currentUser={currentUser} />
          </Route>
        ))}

        {/* {redirects.map((redirect) => (
          <Redirect
            from={redirect.from}
            to={{
              pathname: redirect.to,
              state: redirect.state,
            }}
            exact={redirect.exact}
            key={redirect.key}
          />
        ))} */}

        <Redirect from="/orders/details" to="/orders" exact />
        {/* <Redirect from="/" to="/orders" exact /> */}
      </Switch>
    </Suspense>
  );
}

export default SidebarMainRoutes;
