import { graphql } from '@apollo/client/react/hoc';
import { UPDATE_CUSTOMER_LOC_PREFS } from '@fullcontour/shared-api';

const withUpdate = graphql(UPDATE_CUSTOMER_LOC_PREFS, {
  name: 'update',
  options: (props) => ({
    onCompleted: (data) => {
      const { updateCustomerLocationDesignPreference } = data;
      if (updateCustomerLocationDesignPreference.errorMessages.length) {
        props.setErrorMessages(
          updateCustomerLocationDesignPreference.errorMessages,
        );
      } else {
        props.clearErrorMessages();
        props.toggleEdit();
      }
    },
  }),
});

export default withUpdate;
