import { gql } from '@apollo/client';

export default gql`
  query creditsDropdown(
    $labLocationId: ID
    $labOrganizationId: ID
    $applied: Boolean
  ) {
    credits(
      labLocationId: $labLocationId
      labOrganizationId: $labOrganizationId
      applied: $applied
    ) {
      id
      hexCreditNumber
      name
      value
      applied
      remainingBalance
    }
  }
`;
