function initialValues(props) {
  const { customer, type, contact = {} } = props;

  return {
    id: contact.id || null,
    name: contact.name || '',
    role: 'billing',
    title: contact.title || '',
    email: contact.email || '',
    phone: contact.phone || '',
    extension: contact.extension || '',
    contactableId: contact.contactableId || customer.id,
    contactableType:
      contact.contactableType || type === 'location'
        ? 'LabLocation'
        : 'LabOrganization',
  };
}

export default initialValues;
