import { AgGridReact } from '@ag-grid-community/react';
import { ChangeDetectionStrategyType } from '@ag-grid-community/react/lib/changeDetectionService';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { ConfirmationModal } from '../../..';
import { gridDateComparator, timestampFormatter } from '../../../transforms';
import { AgGridLoader } from '../Loader';
import GridCellDelete from './GridCells/GridCellDelete';
import './ag-grid-css-overrides.css';
import './ag-theme-balham-overrides.css';

function AgGridContainer({
  gridOptions,

  containerHeight = import.meta.env.VITE_APP_NAME === 'customer_portal' ||
  import.meta.env.VITE_APP_NAME === 'designer_portal'
    ? 'calc(100vh - 190px - 3em)'
    : 'calc(100vh - 129px - 3em)',
  shortContainer = false,
  deleteModal = false,
  onRowClicked = () => {},
}) {
  const { frameworkComponents, rowData, context, defaultColDef, ...gridRest } =
    gridOptions;
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  const reviewCase = (data) => {
    setModal(true);
    setSelectedCase(data);
  };

  return (
    <div
      className="ag-theme-balham mainGridContainer"
      style={{
        height: shortContainer ? 300 : containerHeight,
      }}
    >
      <AgGridReact
        modules={AllModules}
        rowDataChangeDetectionStrategy={
          ChangeDetectionStrategyType.IdentityCheck
        }
        gridOptions={gridRest}
        onRowClicked={onRowClicked}
        rowData={rowData}
        headerHeight={40}
        rowHeight={40}
        animateRows
        reactNext
        context={{
          history,
          reviewCase,
          ...context,
        }}
        frameworkComponents={{
          customLoadingOverlay: AgGridLoader,
          gridCellDelete: GridCellDelete,
          ...frameworkComponents,
        }}
        loadingOverlayComponent="customLoadingOverlay"
        floatingFiltersHeight={40}
        onGridSizeChanged={onGridSizeChanged}
        onFirstDataRendered={onFirstDataRendered}
        defaultColDef={{
          resizable: true,
          editable: false,
          suppressMenu: false,
          sortable: true,
          ...defaultColDef,
        }}
        columnTypes={{
          dateColumn: {
            width: 185,
            minWidth: 185,
            filter: 'agDateColumnFilter',
            filterParams: {
              newRowsAction: 'keep',
              comparator: (filterLocalDateAtMidnight, cellValue) =>
                gridDateComparator(filterLocalDateAtMidnight, cellValue),
            },
            valueFormatter: (params) => {
              if (params.value) {
                return timestampFormatter(params.value);
              }

              return params.value;
            },
          },
        }}
      />
      {deleteModal && selectedCase && (
        <ConfirmationModal
          open={modal}
          setModal={setModal}
          text={
            selectedCase.bodyText ||
            `Are you sure you want to delete "${
              selectedCase && selectedCase.name
            }"?`
          }
          data={selectedCase}
        />
      )}
    </div>
  );
}

AgGridContainer.propTypes = {
  gridOptions: PropTypes.object.isRequired,
  containerHeight: PropTypes.any,
  shortContainer: PropTypes.bool,
  deleteModal: PropTypes.bool,
  onRowClicked: PropTypes.func,
};

export default AgGridContainer;
