import { Legal } from '@fullcontour/approval-shared';
import { BaseLoader } from '@fullcontour/common';
import { lazy, Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Redirect, Route, Switch } from 'react-router-dom';

const AsyncSummary = lazy(() => import('../views/Summary'));

function Main() {
  return (
    <Suspense fallback={<BaseLoader />}>
      <main
        style={{
          marginTop: 0,
          marginBottom: 0,
          minHeight: 'calc(100vh - 80px)',
        }}
      >
        <CookiesProvider>
          <Switch>
            <Route
              path="/summary/:linkHash"
              render={({ staticContext, ...rest }) => (
                <AsyncSummary {...rest} />
              )}
              exact
            />
            <Route
              exact
              path="/legal"
              render={({ staticContext, ...rest }) => <Legal {...rest} />}
            />

            <Route
              path="/external"
              component={() => {
                window.location = 'https://smilesummary.com';
                return null;
              }}
            />

            <Redirect to="/external" />
          </Switch>
        </CookiesProvider>
      </main>
    </Suspense>
  );
}

export default Main;
