import PropTypes from 'prop-types';
import { AgGridContainer } from '../../../../../shared';

function ResultsFeedbackAnswer(props) {
  const { reportDetails } = props;

  const gridOptions = {
    rowData: reportDetails,
    defaultColDef: {
      floatingFilter: true,
    },
    suppressAggFuncInHeader: true,
    columnDefs: [
      {
        field: 'order.hexOrderCode',
        headerName: 'Case ID',
        minWidth: 100,
        filter: 'agTextColumnFilter',
        sort: 'asc',
        cellRenderer: 'agGroupCellRenderer',
        cellStyle: {
          color: '#1B77C8',
          cursor: 'pointer',
          textTransform: 'uppercase',
        },
        onCellClicked: (params) => {
          params.context.history.push(
            `/orders/details/${params.data.order.hexOrderCode}`,
          );
        },
      },
      {
        field: 'labLocation.name',
        headerName: 'Location',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'designUser.name',
        headerName: 'Designer',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'designUser.org',
        headerName: 'Design Org',
        minWidth: 120,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'designUser.loc',
        headerName: 'Silo',
        minWidth: 120,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'redesignCount',
        headerName: 'Redesign #',
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'redesignReason',
        headerName: 'Reason',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'rating',
        headerName: 'Overall Rating',
        minWidth: 60,
        filter: 'agTextColumnFilter',
        cellRenderer: (params) => {
          const { value } = params;
          const span = document.createElement('span');
          if (value !== '' && value !== undefined && value !== null) {
            let labelClass;

            if (params.value === 5) {
              labelClass = 'tag is-success';
            }

            if (params.value !== 5) {
              labelClass = 'tag is-danger';
            }
            const label = document.createElement('div');

            label.className = labelClass;
            label.style = `margin: 2% 5px; vertical-align: middle; display: inline-block; text-transform: capitalize; min-width: 40px; text-align: center; color: ${
              params.value ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)'
            }`;
            label.innerHTML = params.value ? '+' : '-';

            span.append(label);

            return span;
          }
          return null;
        },
      },
      {
        field: 'designType',
        headerName: 'Design Type',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'option',
        headerName: 'Option',
        minWidth: 600,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'category',
        headerName: 'Category',
        minWidth: 600,
        filter: 'agTextColumnFilter',
      },
    ],
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Show / Hide Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressPivotMode: false,
            suppressPivots: false,
            suppressRowGroups: false,
            suppressValues: false,
          },
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
    },
  };

  return <AgGridContainer gridOptions={gridOptions} />;
}

ResultsFeedbackAnswer.propTypes = {
  reportDetails: PropTypes.array.isRequired,
};

export default ResultsFeedbackAnswer;
