import PropTypes from 'prop-types';
import { AgGridContainer } from '../../../../../shared';

function ResultsAvgDesignTimeCustomerLoc({ reportDetails }) {
  const gridOptions = {
    rowData: reportDetails,
    defaultColDef: {
      floatingFilter: true,
    },
    suppressAggFuncInHeader: true,
    columnDefs: [
      {
        field: 'name',
        headerName: 'Location',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        sort: 'asc',
        cellStyle: { color: '#1B77C8', cursor: 'pointer' },
        onCellClicked: (params) => {
          params.context.history.push(
            `/locations/customer/${params.data.slug}`,
          );
        },
      },
      {
        field: 'organization',
        headerName: 'Organization',
        minWidth: 150,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'tags',
        headerName: 'Tags',
        minWidth: 120,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'averageDesignTime',
        headerName: 'Average Redesign Time (hours)',
        type: 'numericColumn',
        minWidth: 55,
        filter: 'agTextColumnFilter',
        valueFormatter: (d) => {
          if (d.node.allChildrenCount) {
            return d.value ? (d.value / d.node.allChildrenCount).toFixed(2) : 0;
          }

          return d.value ? d.value.toFixed(2) : 0;
        },
        aggFunc: 'sum',
      },
    ],
  };

  return <AgGridContainer gridOptions={gridOptions} />;
}

ResultsAvgDesignTimeCustomerLoc.propTypes = {
  reportDetails: PropTypes.array.isRequired,
};

export default ResultsAvgDesignTimeCustomerLoc;
