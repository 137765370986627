import { useFormik } from 'formik';
import { Message } from '@fullcontour/common';
import PropTypes from 'prop-types';
import { useTranslation, Translation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function ForgotPassword({
  forgotPassword,
  error,
  cleanError = null,
}) {
  const { t } = useTranslation('signin');
  const { handleSubmit, handleChange, values, errors, isSubmitting } =
    useFormik({
      initialValues: {
        username: '',
      },
      onSubmit: async (values) => {
        await forgotPassword({
          username: values.username.trim().toLowerCase(),
        });
      },
      validate: (values) => {
        const errors = {};
        if (values.username === '') {
          errors.username = 'Please enter your username. Required';
        }
        return errors;
      },
    });
  return (
    <>
      {error &&
        error !== 'User needs to be authenticated to call this API.' && (
          <Translation ns={['signin']}>
            {(t) => (
              <Message
                type="danger"
                title="Password Recovery"
                dismissable
                error={error}
                onDismiss={cleanError}
              >
                <p>{t(error)}</p>
              </Message>
            )}
          </Translation>
        )}
      <form onSubmit={handleSubmit}>
        <div className="field">
          <p className="control has-icons-left has-icons-right">
            <input
              className="input"
              type="text"
              onChange={handleChange}
              value={values.username}
              placeholder={t('Enter your email or username')}
              name="username"
            />
            <span className="icon is-small is-left">
              <i className="bx bxs-envelope" />
            </span>
            <span className="icon is-small is-right">
              {errors.username ? (
                <i className="bx bxs-error" />
              ) : (
                <i className="bx bx-check" />
              )}
            </span>
          </p>
          <p className="help is-danger">{errors.username}</p>
        </div>
        <div className="field is-grouped is-grouped-centered mt-5">
          <p className="control">
            <button
              className={`button has-background-primary has-text-white input ${
                isSubmitting ? 'is-loading' : ''
              }`}
              type="submit"
            >
              {t('Send Code')}
            </button>
          </p>
          <Link
            to="/signin"
            className="button has-background-grey has-text-white"
          >
            {t('Cancel and Sign In')}
          </Link>
        </div>
      </form>
    </>
  );
}

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  cleanError: PropTypes.func,
};
