import { AgGridReact } from '@ag-grid-community/react';
import { ChangeDetectionStrategyType } from '@ag-grid-community/react/lib/changeDetectionService';

import { AllModules } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  gridDateComparator,
  timestampFormatter,
} from '../../../../../transforms';
import { AgGridLoader, BoxIcon, ExpireComponent } from '../../../../shared';
import '../../../../shared/AgGrid/ag-grid-css-overrides.css';
import '../../../../shared/AgGrid/ag-theme-balham-overrides.css';
import { FollowUpModalWrapper } from '../../shared/FollowUpModal';
import { ReviewModal } from '../../shared/ReviewModal';
import {
  GridCellAction,
  GridCellApproval,
  GridCellDueTime,
  GridCellStatus,
  GridCellStyled,
  GridCellTags,
  GridCellTier,
} from './GridCells';

class OrdersListGrid extends Component {
  state = {
    modalOpen: false,
    order: null,
  };

  closeModal = () => {
    this.setState({ modalOpen: false, order: null });
  };

  openModal = () => {
    this.setState({ modalOpen: true });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  reviewCase = (data) => {
    this.setState({ order: data }, () => {
      this.openModal();
    });
  };

  // eslint-disable-next-line class-methods-use-this
  getContextMenuItems = (params = {}) => {
    if (params?.value) {
      params.defaultItems.push({
        name: `Open in new tab`,
        icon: `<span class="ag-icon ag-icon-small-up" unselectable="on" role="presentation"></span>`,
        action() {
          window
            .open(
              `${window.location.origin}/orders/details/${params.value}`,
              '_blank',
            )
            ?.focus();
        },
      });
    }

    return params.defaultItems;
  };

  translateHeaders = (headers) => {
    const { t } = this.props;
    return headers.map((header) => {
      if (header.children) {
        return {
          ...header,
          headerName: t(header.headerName),
          children: this.translateHeaders(header.children),
        };
      }
      return {
        ...header,
        headerName: t(header.headerName),
      };
    });
  };

  render() {
    const {
      containerHeight,
      gridOptions,
      rowData,
      history,
      onGridReady,
      onGridSizeChanged,
      onFirstDataRendered,
      onColumnMoved,
      onDisplayedColumnsChanged,
      onColumnVisible,
      onSelectionChanged,
      location,
      loading,
      t,
    } = this.props;

    const { modalOpen, order } = this.state;

    const { frameworkComponents, columnDefs, ...gridRest } = gridOptions;

    return (
      <>
        <div
          className="ag-theme-balham mainGridContainer ordersGridContainer"
          role={rowData ? 'gridStateCompleted' : 'gridStateLoading'}
          style={{
            height: containerHeight,
            position: 'relative',
          }}
        >
          <AgGridReact
            getContextMenuItems={this.getContextMenuItems}
            modules={AllModules}
            rowDataChangeDetectionStrategy={
              ChangeDetectionStrategyType.IdentityCheck
            }
            gridOptions={{
              ...gridRest,
              localeTextFunc: (_key, defaultValue) => t(defaultValue),
            }}
            headerHeight={40}
            rowHeight={40}
            rowData={rowData}
            animateRows
            columnDefs={[
              {
                field: '',
                width: 40,
                minWidth: 40,
                filter: false,
                sortable: false,
                suppressMenu: true,
                suppressColumnsToolPanel: true,
                lockPosition: true,
                resizable: false,
                suppressSizeToFit: true,
                suppressMovable: true,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                pinned: 'left',
              },
              ...this.translateHeaders(columnDefs),
            ]}
            reactNext
            context={{
              history,
              componentParent: this,
            }}
            frameworkComponents={{
              customLoadingOverlay: AgGridLoader,
              gridCellStatus: GridCellStatus,
              gridCellApproval: GridCellApproval,
              gridCellAction: GridCellAction,
              gridCellTags: GridCellTags,
              gridCellStyled: GridCellStyled,
              gridCellTier: GridCellTier,
              gridCellDueTime: GridCellDueTime,
              ...frameworkComponents,
            }}
            loadingOverlayComponent="customLoadingOverlay"
            floatingFiltersHeight={46}
            onGridReady={onGridReady}
            onGridSizeChanged={onGridSizeChanged}
            onFirstDataRendered={onFirstDataRendered}
            onColumnMoved={onColumnMoved}
            onDisplayedColumnsChanged={onDisplayedColumnsChanged}
            onColumnVisible={onColumnVisible}
            groupHeaderHeight={30}
            rowSelection="multiple"
            onSelectionChanged={onSelectionChanged}
            statusBar={{
              statusPanels: [
                {
                  statusPanel: 'agTotalAndFilteredRowCountComponent',
                  align: 'left',
                },
              ],
            }}
            defaultColDef={{
              resizable: true,
              editable: false,
              suppressMenu: false,
              sortable: true,
              filterParams: { newRowsAction: 'keep' },
              floatingFilter: true,
            }}
            columnTypes={{
              dateColumn: {
                width: 185,
                minWidth: 185,
                filter: 'agDateColumnFilter',
                filterParams: {
                  newRowsAction: 'keep',
                  comparator: (filterLocalDateAtMidnight, cellValue) =>
                    gridDateComparator(filterLocalDateAtMidnight, cellValue),
                },
                valueFormatter: (params) => {
                  if (params.value) {
                    return timestampFormatter(params.value);
                  }

                  return params.value;
                },
              },
              approvalColumn: {
                width: 130,
                minWidth: 130,
                filter: 'agSetColumnFilter',
                cellRenderer: 'gridCellApproval',
                filterParams: { newRowsAction: 'keep' },
                headerTooltip: t(
                  'No need for approval before order is sent for manufacturing or needs an approval before order is sent for manufacturing',
                ),
              },
              orderIdColumn: {
                width: 150,
                minWidth: 150,
                filter: 'agTextColumnFilter',
                filterParams: { newRowsAction: 'keep' },
                cellStyle: {
                  color: 'rgb(27, 119, 200)',
                  cursor: 'pointer',
                  textTransform: 'uppercase',
                },
                onCellClicked: (params) => {
                  params.context.history.push(
                    `/orders/details/${params.value}`,
                    { prevLocation: location.pathname },
                  );
                },
              },
            }}
            sideBar={{
              toolPanels: [
                {
                  id: 'columns',
                  labelDefault: t('Show / Hide Columns'),
                  labelKey: 'columns',
                  iconKey: 'columns',
                  toolPanel: 'agColumnsToolPanel',
                  toolPanelParams: {
                    suppressPivotMode: true,
                    suppressPivots: true,
                    suppressRowGroups: true,
                    suppressValues: true,
                  },
                },
                {
                  id: 'filters',
                  labelDefault: t('Filters'),
                  labelKey: 'filters',
                  iconKey: 'filter',
                  toolPanel: 'agFiltersToolPanel',
                },
              ],
            }}
          />
          <ExpireComponent delay={40000}>
            <div
              style={{ position: 'absolute', bottom: '13px', right: '20px' }}
            >
              {loading ? (
                'loading'
              ) : (
                <span
                  style={{
                    fontSize: '10px',
                    marginLeft: '12px',
                  }}
                >
                  {t('Table is up-to-date')}{' '}
                  <BoxIcon name="bx-check-circle has-text-success bx-xs" />
                </span>
              )}
            </div>
          </ExpireComponent>
        </div>
        {modalOpen &&
        (import.meta.env.VITE_APP_NAME === 'admin_portal' ||
          import.meta.env.VITE_APP_NAME === 'designer_portal' ||
          import.meta.env.VITE_APP_NAME === 'customer_portal') ? (
          <ReviewModal
            closeModal={this.closeModal}
            modalOpen={modalOpen}
            order={order}
            {...this.props}
          />
        ) : null}
        <FollowUpModalWrapper />
      </>
    );
  }
}

OrdersListGrid.propTypes = {
  gridOptions: PropTypes.object.isRequired,
  rowData: PropTypes.array,
  containerHeight: PropTypes.string,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  loading: PropTypes.bool.isRequired,

  onGridReady: PropTypes.func.isRequired,
  onGridSizeChanged: PropTypes.func.isRequired,
  onFirstDataRendered: PropTypes.func.isRequired,
  onColumnMoved: PropTypes.func.isRequired,
  onDisplayedColumnsChanged: PropTypes.func.isRequired,
  onColumnVisible: PropTypes.func.isRequired,
  onSelectionChanged: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

OrdersListGrid.defaultProps = {
  rowData: null,
  containerHeight:
    import.meta.env.VITE_APP_NAME === 'customer_portal' ||
    import.meta.env.VITE_APP_NAME === 'designer_portal'
      ? 'calc(100vh - 190px - 3em)'
      : 'calc(100vh - 129px - 3em)',
};

export default withRouter(withTranslation('aggrid')(OrdersListGrid));
