import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { gridDateComparator, timestampFormatter } from '../../../../transforms';
import BillingCreditsHeader from '../../Billing/BillingCredits/BillingCreditsHeader';
import BillingGrid from '../../Billing/BillingGrid/BillingGrid';

function CreditsListDataTable(props) {
  const { credits, setSelectedItem } = props;

  const { root } = useParams();
  const [toggleApplied, setToggleApplied] = useState(false);
  const [rowData, setRowData] = useState([]);

  const app = import.meta.env.VITE_APP_NAME;

  function getUrl(params) {
    if (app === 'customer_portal') {
      return `/locations/${params.data.location.slug}`;
    }

    return `/locations/customer/${params.data.location.slug}`;
  }

  useEffect(() => {
    setRowData(credits.filter((credit) => !credit.applied));
  }, [credits]);

  useEffect(() => {
    setSelectedItem(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleApplied]);

  const gridOptions = {
    defaultColDef: {
      floatingFilter: true,
    },
    rowSelection: 'single',
    masterDetail: true,
    columnDefs: [
      {
        field: 'hexCreditNumber',
        headerName: 'Credit ID',
        minWidth: 150,
        sort: 'desc',
        filter: 'agTextColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
        cellStyle: {
          textTransform: 'uppercase',
        },
      },
      {
        field: 'organization.name',
        headerName: 'Organization',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        cellStyle: { color: '#1B77C8', cursor: 'pointer' },
        onCellClicked: (params) => {
          params.context.history.push(
            `/organizations/customer/${params.data.organization.slug}`,
          );
        },
        hide: root !== 'billing',
      },
      {
        field: 'location.name',
        headerName: 'Location',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        cellStyle: { color: '#1B77C8', cursor: 'pointer' },
        onCellClicked: (params) => {
          if (params.data.location.slug) {
            params.context.history.push(getUrl(params));
          }

          return null;
        },
        hide:
          (app === 'admin_portal' && root !== 'billing') ||
          (app === 'customer_portal' && root !== 'organizations'),
      },
      {
        field: 'applied',
        headerName: 'Status',
        minWidth: 150,
        filter: 'agSetColumnFilter',
        valueGetter: (params) =>
          params.data.applied ? 'Applied' : 'Not Applied',
      },
      {
        field: 'reason',
        headerName: 'Memo',
        minWidth: 150,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'amount',
        headerName: 'Credit Amount',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        valueFormatter: (p) => `$${Number.parseFloat(p.value).toFixed(2)}`,
      },
      {
        headerName: 'Total Applied',
        columnGroupShow: 'open',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        valueGetter: (params) =>
          params.data.amount - params.data.remainingBalance,
        valueFormatter: (p) => `($${Number.parseFloat(p.value).toFixed(2)})`,
      },
      {
        field: 'remainingBalance',
        headerName: 'Remaining Balance',
        columnGroupShow: 'open',
        minWidth: 150,
        filter: 'agTextColumnFilter',
        valueFormatter: (p) => `$${Number.parseFloat(p.value).toFixed(2)}`,
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        minWidth: 150,
        type: 'dateColumn',
      },
    ],
    detailCellRendererParams: {
      detailGridOptions: {
        columnDefs: [
          {
            field: 'invoice.hexInvoiceNumber',
            headerName: 'Invoice',
            minWidth: 150,
            cellStyle: {
              textTransform: 'uppercase',
            },
          },
          {
            field: 'amountUsed',
            headerName: 'Amount Applied',
            minWidth: 150,
            valueFormatter: (p) => `$${Number.parseFloat(p.value).toFixed(2)}`,
          },
          {
            field: 'createdAt',
            headerName: 'Date Applied',
            minWidth: 150,
            filter: 'agDateColumnFilter',
            filterParams: {
              newRowsAction: 'keep',
              comparator: (filterLocalDateAtMidnight, cellValue) =>
                gridDateComparator(filterLocalDateAtMidnight, cellValue),
            },
            valueFormatter: (params) => timestampFormatter(params.value, false),
          },
        ],
        onFirstDataRendered(params) {
          params.api.sizeColumnsToFit();
        },
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.creditTransactions);
      },
    },
  };

  const toggleAppliedView = () => {
    setRowData(credits.filter((credit) => credit.applied === !toggleApplied));
    setToggleApplied(!toggleApplied);
  };

  return (
    <div>
      <BillingCreditsHeader
        {...props}
        toggleAppliedView={toggleAppliedView}
        toggleApplied={toggleApplied}
      />

      <div className="columns">
        <div className="column billingGridWrap">
          <BillingGrid
            gridOptions={gridOptions}
            rowData={rowData}
            containerHeight={550}
            {...props}
          />
        </div>
      </div>
    </div>
  );
}

CreditsListDataTable.propTypes = {
  credits: PropTypes.array.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
};

export default CreditsListDataTable;
